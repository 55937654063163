import { Spin } from "antd";
import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="loader-container">
      <div className="loader">
      <Spin />
      </div>
    </div>
  );
};

export default LoadingSpinner;
