import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import "assets/styles/base.scss";
import Switch from "components/Switch";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { BiCopy } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { connect } from "react-redux";
import toaster from "../../../modules/toaster";
import {
  duplicateProject,
  editProjectStatus,
  loadProjectsList,
} from "../../../reducers/Projects";
import "./table.css";
import { loadORGsList } from "../../../reducers/ORGs";
class TableWithSwitch extends Component {
  state = {
    selected: [],
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  getActivityName = (id) => {
    let name = id;
    if (this.props.activities && this.props.activities.length > 0)
      for (let act of this.props.activities)
        if (act.id == id) name = act.nameAR;
    return name;
  };

  getNGOName = (id) => {
    let name = id;
    if (this.props.orgs && this.props.orgs.length > 0)
      for (let org of this.props.orgs) if (org.id == id) name = org.nameAR;
    return name;
  };

  handleDuplicate = (project) => {
    this.props.duplicate(project).then(() => {
      this.nextPath("/projects/new?duplicate=true");
    });
  };

  toggleActive = (project) => {
    this.props
      .editStatus({
        id: project.id,
        new_status: project.isactive == 1 ? 0 : 1,
      })
      .then(() => {
        project.isactive = project.isactive == 1 ? 0 : 1;
        this.props.loadProjects(this.props.selectedOrg);
        toaster.success("Project's status has been changed successfully.");
      });
  };

  handleEdit = (project) => {
    this.props.duplicate(project).then(() => {
      this.nextPath("/projects/new?edit=true");
    });
  };

  nextPath(path) {
    this.context.router.history.push(path);
  }

  render() {
    const org=this.props.orgs.filter((org)=>{return org.id===this.props.project.orgid})[0]?.intID
    const act=this.props.activities.filter((act)=>{return act.id===this.props.project.actid})[0]?.intID

    return (
      <React.Fragment>
        <TableRow
          hover
          aria-checked={this.props.isItemSelected}
          tabIndex={-1}
          key={this.props.key}
          selected={this.props.isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) =>
                this.props.handleClick(event, this.props.project.id)
              }
              role="checkbox"
              checked={this.props.isItemSelected}
              inputProps={{ "aria-labelledby": this.props.labelId }}
            />
          </TableCell>
          {this.props.headSelected.ID && (
            <TableCell>{this.props.project.id}</TableCell>
          )}
          {this.props.headSelected.Category && (
            <TableCell>
              {this.getActivityName(this.props.project.actid)}
            </TableCell>
          )}
          {this.props.headSelected.ORG && (
            <TableCell>{this.getNGOName(this.props.project.orgid)}</TableCell>
          )}
          {this.props.headSelected.NameAR && (
            <TableCell>{this.props.project.nameAR}</TableCell>
          )}
          {this.props.headSelected.NameEN && (
            <TableCell>{this.props.project.nameEN}</TableCell>
          )}
          {this.props.headSelected.DescriptionAR && (
            <TableCell
              dangerouslySetInnerHTML={{ __html: this.props.project.infoAR }}
            ></TableCell>
          )}
          {this.props.headSelected.DescriptionEN && (
            <TableCell
              dangerouslySetInnerHTML={{ __html: this.props.project.infoEN }}
            ></TableCell>
          )}
          {this.props.headSelected.Order && (
            <TableCell>{this.props.project.Order}</TableCell>
          )}
          {this.props.headSelected.SmartDeeplink && (
            <TableCell>
              {this.props.project.smartDeepLink}
              {this.props.project.smartDeepLink !== "" && (
                <i
                  className="fa fa-clone pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      this.props.project.smartDeepLink
                    );
                  }}
                ></i>
              )}
            </TableCell>
          )}
          
          {this.props.headSelected.Deeplink && (
            <TableCell>
              {org&&act&&`jonation://screen?target=PaymentScreen_${act}_${org}`}
                <i
                  className="fa fa-clone pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(`jonation://screen?target=PaymentScreen_${act}_${org}`);
                  }}
                ></i>
            
            </TableCell>
          )}
          {this.props.headSelected.MediaURL && (
            <TableCell>{this.props.project.mediaURL}</TableCell>
          )}
          {this.props.headSelected.Status && (
            <TableCell>
              <Switch
                value={this.props.project.isactive}
                onText={"Active"}
                offText={"OFF"}
                onChange={() => this.toggleActive(this.props.project)}
              />
            </TableCell>
          )}
          {this.props.headSelected.Actions && (
            <TableCell>
              <div className="TableIcons">
                <FaRegEdit
                  size={24}
                  color="#00AEEF"
                  onClick={() => {
                    this.handleEdit(this.props.project);
                  }}
                />

                <BiCopy
                  cursor="pointer"
                  onClick={() => {
                    this.handleDuplicate(this.props.project);
                  }}
                  color="#00AEEF"
                  size={24}
                />
              </div>
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    );
  }
}

const mapDispatchToProp = (dispatch) => ({
  loadProjects: (orgId) => dispatch(loadProjectsList(orgId)),
  editStatus: (data) => dispatch(editProjectStatus(data)),
  duplicate: (project) => dispatch(duplicateProject(project)),
  loadAllORGs: () => dispatch(loadORGsList()),
});

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.selectedOrg,
    projects: state.Projects.projects,
    activities: state.Activities.activities,
    orgs: state.ORGs.orgs,
  }),
  mapDispatchToProp
)(TableWithSwitch);

TableWithSwitch.propTypes = {
  headSelected: PropTypes.object,
  project: PropTypes.object,
  isItemSelected: PropTypes.bool,
  labelId: PropTypes.string,
  handleClick: PropTypes.func,
  duplicate: PropTypes.func,
  editStatus: PropTypes.func,
  loadProjects: PropTypes.func,
  selectedOrg: PropTypes.number,
  activities: PropTypes.array,
  orgs: PropTypes.array,
};
