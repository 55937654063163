import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

const SelectedVideosDataTable = (props) => {
  const [show, setShow] = useState(false);

  const {
    deleteSelectedVideo,
    selectedVideo,
    isItemSelected,
    labelId,
    key,
    handleClick,
    headSelected,
  } = props;

  const openModalHandler = () => setShow(true);

  const deleteVideoHandler = () => deleteSelectedVideo(selectedVideo.objectId);
  return (
    <React.Fragment>
      <TableRow
        hover
     
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={key}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
             onClick={(event) => handleClick(event, selectedVideo.objectId)}
             role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>

        {headSelected.URL && <TableCell>{selectedVideo.url}</TableCell>}
        {headSelected.CreatedAt && (
          <TableCell>{dateFormatter(selectedVideo.createdAt)}</TableCell>
        )}
        {headSelected.objectId && (
          <TableCell>{selectedVideo.objectId}</TableCell>
        )}

        {headSelected.Actions && (
          <TableCell>
            <div className="TableIcons">

            <Link to={`/editSelectedVideo/${selectedVideo.objectId}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>
            <RiDeleteBinLine
              cursor="pointer"
              onClick={openModalHandler}
              color="#ED1650"
              size={24}
            />
            </div>
          </TableCell>
        )}
      </TableRow>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${selectedVideo.url} from selected videos?`}
          setShow={setShow}
          show={show}
        >
          <button className="btn btn-danger" onClick={deleteVideoHandler}>
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default SelectedVideosDataTable;
