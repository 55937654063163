import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { useEffect, useState } from "react";
import { Button, FormGroup } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  createNotification,
  editNotification,
  getNotification,
} from "../../reducers/Notifications";
import "./AddNotification.css";

const AddNotification = ({
  history,
  isPending,
  notification,
  getNotification,
  editNotification,
  createNotification,
}) => {
  const notificationId =
    window.location.hash.includes("editNotification") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [notificationText, setNotificationText] = useState(
    EditorState.createEmpty()
  );
  const [notificationTextInAR, setNotificationTextInAR] = useState(
    EditorState.createEmpty()
  );
  const [isNotificationRequired, setIsNotificationRequired] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (notificationId) {
      getNotification(notificationId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [notificationId]);

  const submitNotificationsPersonDataHandler = (e) => {
    e.preventDefault();
    const newNotificationData = {
      // notificationText,
      notificationText: stateToHTML(notificationText.getCurrentContent()),

      notificationTextInAR: stateToHTML(
        notificationTextInAR.getCurrentContent()
      ),
    };

    if (isEditingMode) {
      editNotification(notificationId, newNotificationData);
    } else if (!isEditingMode && notificationText) {
      createNotification(newNotificationData);
    } else {
      setError("url is required");
      return;
    }
    // wipeAmbassadorImageData();
    history.push("/notification");
  };

  useEffect(() => {
    if (notification && notificationId) {
      // setNotificationText(notification.notificationText);
      setNotificationText(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              notification.notificationText
                ? notification.notificationText
                : `<p>No Notification</p>`
            )
          )
        )
      );
      // setNotificationTextInAR(notification.notificationTextInAR);
      setNotificationTextInAR(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              notification.notificationTextInAR
                ? notification.notificationTextInAR
                : `<p>No Notification</p>`
            )
          )
        )
      );
      setEdit(true);
    }
  }, [notification]);

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <div className="header">
        <h4 className="title">
          {isEditingMode ? `Edit Notification` : "Add New Notification"}
        </h4>
      </div>
      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={submitNotificationsPersonDataHandler}
      >
        <FormGroup>
          <div className="fieldDiv form-group">
            <label id="notificationText" className="control-label">Notification Text In EN</label>

            <Editor
              editorState={notificationText}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setNotificationText(e)}
              onFocus={() => setIsNotificationRequired(false)}
              onBlur={() =>
                notificationText.getCurrentContent().getPlainText().trim()
                  .length <= 0 && setIsNotificationRequired(true)
              }
            />

            {isNotificationRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Notification field is required
              </p>
            )}
          </div>
        </FormGroup>

        <FormGroup>
          <div className="fieldDiv form-group">
            <label id="notificationTextInAR" className="control-label">Notification Text In AR</label>

            <Editor
              editorState={notificationTextInAR}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setNotificationTextInAR(e)}
              onFocus={() => setIsNotificationRequired(false)}
              onBlur={() =>
                notificationTextInAR.getCurrentContent().getPlainText().trim()
                  .length <= 0 && setIsNotificationRequired(true)
              }
            />

            {isNotificationRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Notification field is required
              </p>
            )}
          </div>
        </FormGroup>
        <div className="submit">
          <Button
            // disabled={!edit}
            className="create-edit-form-btn"
            type="submit"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Notifications.isPending,
  notification: state.Notifications.notification,
});

const actions = (dispatch) => ({
  getNotification: (notificationId) =>
    dispatch(getNotification(notificationId)),
  editNotification: (notificationId, newNotificationData) =>
    dispatch(editNotification(notificationId, newNotificationData)),
  createNotification: (newNotificationData) =>
    dispatch(createNotification(newNotificationData)),
});

export default connect(mapStateToProps, actions)(AddNotification);
