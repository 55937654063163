import React from "react";
import './switch.css';
const SwitchControl = ({value,
  onChange,
  onText,
  offText
 }) => (
  <div className="">
  <div className={`${value? 'Active':'nonActive'}`}
    onClick={() => onChange(!value)}>
      {value?

        <div className="switch-active"></div>
        :<div className="switch-nonActive"></div>}
    
    
    
  </div>
</div>

);

SwitchControl.defaultProps = {
  onText: "ON",
  offText: "OFF",
  onChange: () => {},
};

export default SwitchControl;
