import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NewDataTable from "../../components/NewDataTable";
import RamadanNotesDataTable from "../../components/RamadanNotesDataTable/RamadanNotesDataTable";
import {
  deleteRamadanNote,
  getAllRamadanNotes,
} from "../../reducers/RamadanNotes";
import { Pagination, PaginationItem } from "@material-ui/lab";
import toaster from "../../modules/toaster";

const selecttableHead = [
  "objectId",
  "content",
  "createdAt",
  "updatedAt",
  "daynum",
  "name",
  "Actions",
];

const rowsPerPage = 10;

const RamadanNotes = ({
  isPending,
  loadRamadanNotes,
  allRamadanNotes,
  history,
  deleteRamadanNote,
}) => {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    objectId: false,
    content: true,
    createdAt: false,
    updatedAt: false,
    daynum: false,
    name: false,
    Actions: true,
  });
  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);
  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };
  useEffect(() => {
    loadRamadanNotes();
  }, []);

  const addNewRamadanNote = () => {
    history.push("addNewRamadanNote");
  };

  if (isPending) {
    return <LoadingSpinner />;
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
    // setSkip((value - 1) * 20);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleDelete=()=>{
    if (selected.length === 0) {
      toaster.warning("please select some notes");
    } else {
      Promise.allSettled(
        selected.map(e=> {
          return deleteRamadanNote(e)
        })
      ).then(responseArr => {
        responseArr.forEach(res=>{
          console.log(res);
      
        })
      })
    }

   
  
  }
  return (
    <div>
      <div className="header">
        <h4 className="title">Ramadan Notes</h4>
      </div>
      <div className="card">
        {allRamadanNotes.length > 0 ? (
          <NewDataTable
            title={"Add New Ramadan Note"}
            csvTitle={"Ramadan Notes"}
            add={addNewRamadanNote}
            data={allRamadanNotes}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
            
              <Pagination 
              // size="small" 
              page={page}
              count={Math.ceil(allRamadanNotes.length/10)}
              // total={projects.length} 
              onChange={handlePaginationChange} />
            }
          >
            {allRamadanNotes
              ?.slice((page-1) * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.objectId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <RamadanNotesDataTable
                    key={row.objectId}
                    ramadanNote={row}
                    deleteRamadanNote={deleteRamadanNote}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
          <p style={{ textAlign: "center" }}>No  RamadanNotes Found</p>
          <button
            className="addButton"
            onClick={() => {
              addNewRamadanNote()
            }}
          >
            <BsPlus size={24} />
            Add A new Ramadan Note
          </button>
        </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    isPending: state.RamadanNotes.isPending,
    allRamadanNotes: state.RamadanNotes.allRamadanNotes,
  }),
  (dispatch) => ({
    loadRamadanNotes: () => dispatch(getAllRamadanNotes()),
    deleteRamadanNote: (id) => dispatch(deleteRamadanNote(id)),
  })
)(RamadanNotes);

RamadanNotes.propTypes = {
  isPending: PropTypes.bool,
  allRamadanNotes: PropTypes.array,
  loadRamadanNotes: PropTypes.func,
  deleteRamadanNote: PropTypes.func,
  history: PropTypes.object,
};
