import React from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import "../assets/styles/base.scss";

const Dropdown = ({ icon, title, numSelected, menu }) => {
  const [select, setSelect] = React.useState(false);

  return (
    <React.Fragment>
      <button
        className="selectButton"
        onClick={() => {
          select ? setSelect(false) : setSelect(true);
        }}
      >
        {icon && icon}
        {numSelected && numSelected}
        {title}
        {select ? (
          <MdOutlineKeyboardArrowUp size={24} color="#00AEEF" />
        ) : (
          <MdOutlineKeyboardArrowDown size={24} color="#00AEEF" />
        )}
      </button>
      {select && menu}
    </React.Fragment>
  );
};
export default Dropdown;
