import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

const RamadanNotesDataTable = (props) => {
  const [show, setShow] = useState(false);

  const {
    deleteRamadanNote,
    ramadanNote,
    handleClick,
    isItemSelected,
    key,
    labelId,
    headSelected,
  } = props;

  const openModalHandler = () => setShow(true);

  const deleteRamadanNoteHandler = () =>
    deleteRamadanNote(ramadanNote.objectId);
  return (
    <React.Fragment>
      <TableRow
        hover
     
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={key}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
             onClick={(event) => handleClick(event, ramadanNote.objectId)}
        role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>

        {headSelected.objectId && <TableCell>{ramadanNote.objectId}</TableCell>}
        {headSelected.content && <TableCell>{ramadanNote.content}</TableCell>}
        {headSelected.createdAt && (
          <TableCell>{dateFormatter(ramadanNote.createdAt)}</TableCell>
        )}
        {headSelected.updatedAt && (
          <TableCell>{dateFormatter(ramadanNote.updatedAt)}</TableCell>
        )}
        {headSelected.daynum && <TableCell>{ramadanNote.daynum}</TableCell>}
        {headSelected.name && <TableCell>{ramadanNote.name}</TableCell>}
        {headSelected.Actions && (
          <TableCell >
            <div className="TableIcons">

            <Link to={`/editRamadanNote/${ramadanNote.objectId}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>
            <RiDeleteBinLine
              cursor="pointer"
              onClick={openModalHandler}
              color="#ED1650"
              size={24}
            />
            </div>
          </TableCell>
        )}
      </TableRow>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${ramadanNote.content} from Ramadan Notes?`}
          setShow={setShow}
          show={show}
        >
          <button className="btn btn-danger" onClick={deleteRamadanNoteHandler}>
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default RamadanNotesDataTable;
