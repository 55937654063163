/* eslint-disable no-undef */
import axios from "axios";
import _ from "lodash";

const defaultState = {
  keywords: [],
  keywordsCount: 0,
  isPending: false,
  keyword: { title: "", org_id: "" },
  error: null,
  filteredKeywords: [],
};

const CREATE_KEYWORD = "CREATE_KEYWORD";
const GET_KEYWORD = "GET_KEYWORD";
const EDIT_KEYWORD = "EDIT_KEYWORD";
const DELETE_KEYWORD = "DELETE_KEYWORD";
const GET_KEYWORDS = "GET_KEYWORDS";
const GET_KEYWORDS_COUNT = "GET_KEYWORDS_COUNT";
const KEYWORD_PENDING = "KEYWORD_PENDING";
const FILTER_KEYWORDS = "FILTER_KEYWORDS";
const FILTER_ALL = "FILTER_ALL";

//axiost headers
const headers = {
  baseURL: `https://appbackend.appgain.io/${process.env.ACCOUNTID}/${process.env.SUBDOMAIN}/classes/`,
  headers: {
    "X-Parse-Application-Id": process.env.APPLICATIONID.replace("'", ""),
    "X-Parse-Master-Key":
      "MASTER-5de399a9d4c40ae9fd03883547fa4e636b1f75f0dcf7d4ac754aea9e3ff3526d",
  },
};
export const getKeywords = (skipVal) => async (dispatch) => {
  dispatch({ type: KEYWORD_PENDING });
  try {
    getKeywords();

    let res = await axios.get(
      `${headers.baseURL}Keywords?order=-createdAt`,
      headers
    );

    dispatch({ type: GET_KEYWORDS, payload: res.data.results });
  } catch (err) {
    dispatch({ type: GET_KEYWORDS, payload: [] });
  }
};

export const getKeywordsCount = () => async (dispatch) => {
  dispatch({ type: KEYWORD_PENDING });
  try {
    let res = await axios.get(
      `${headers.baseURL}Keywords?limit=0&count=1`,
      headers
    );

    dispatch({ type: GET_KEYWORDS_COUNT, payload: res.data.count });
  } catch (err) {
    dispatch({ type: GET_KEYWORDS_COUNT, payload: 0 });
  }
};

export const getKeyword = (objectId) => (dispatch) => {
  dispatch({ type: KEYWORD_PENDING });
  try {
    dispatch({ type: GET_KEYWORD, payload: objectId });
  } catch (err) {
    dispatch({ type: GET_KEYWORD, payload: [] });
  }
};
export const filterKeywords = (orgId) => (dispatch) => {
  dispatch({ type: KEYWORD_PENDING });
  if (orgId === "all") {
    dispatch({ type: FILTER_ALL, payload: orgId });
  } else {
    dispatch({ type: FILTER_KEYWORDS, payload: orgId });
  }
};
export const createKeyword = (keyword) => async (dispatch) => {
  dispatch({ type: KEYWORD_PENDING });
  try {
    let res = await axios.post(`${headers.baseURL}Keywords`, keyword, headers);
    dispatch({
      type: CREATE_KEYWORD,
      payload: {
        objectId: res.data.objectId,
        createdAt: res.data.createdAt,
        updatedAt: res.data.createdAt,
        title: keyword.title,
        org_id: keyword.org_id,
      },
    });
  } catch (err) {
    dispatch({ type: CREATE_KEYWORD, payload: null });
  }
};
export const editKeyword = (keyword) => async (dispatch) => {
  dispatch({ type: KEYWORD_PENDING });
  try {
    let res = await axios.put(
      `${headers.baseURL}Keywords/${keyword.objectId}`,
      keyword,
      headers
    );
    dispatch({
      type: EDIT_KEYWORD,
      payload: res.keyword.objectId,
    });
  } catch (err) {
    dispatch({ type: EDIT_KEYWORD, payload: null });
  }
};
export const deleteKeyword = (objectId) => async (dispatch) => {
  dispatch({ type: KEYWORD_PENDING });
  try {
    await axios.delete(`${headers.baseURL}Keywords/${objectId}`, headers);
    dispatch({ type: DELETE_KEYWORD, payload: objectId });
  } catch (err) {
    dispatch({ type: DELETE_KEYWORD, payload: null });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CREATE_KEYWORD:
      return { ...state, keywords: [...state.keywords, action.payload] };
    case EDIT_KEYWORD:
      return {
        ...state,
        isPending: false,
        error: false,
      };
    case GET_KEYWORDS:
      return {
        ...state,
        keywords: action.payload,
        filteredKeywords: action.payload,
        isPending: false,
        error: false,
      };
    case GET_KEYWORDS_COUNT:
      return { ...state, keywordsCount: action.payload, isPending: false };
    case FILTER_KEYWORDS: {
      const temp = _.cloneDeep(state.keywords);

      return {
        ...state,
        filteredKeywords: _.filter(temp, { org_id: action.payload }),
        keywordsCount: state.filteredKeywords.length,
        isPending: false,
        error: false,
      };
    }
    case FILTER_ALL:
      return {
        ...state,
        filteredKeywords: state.keywords,
        isPending: false,
        error: false,
      };
    case GET_KEYWORD:
      return {
        ...state,
        keyword: _.filter(state.keywords, (keyword) => {
          return keyword.objectId === action.payload;
        })[0],
        isPending: false,
      };
    case DELETE_KEYWORD:
      return {
        ...state,
        keywords: _.filter(
          state.keywords,
          (keyword) => keyword.objectId !== action.payload
        ),
        filteredKeywords: _.filter(
          state.keywords,
          (keyword) => keyword.objectId !== action.payload
        ),

        isPending: false,
      };
    case KEYWORD_PENDING:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
