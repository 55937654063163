import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import Switch from "components/Switch";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import toaster from "../../modules/toaster";
import "./DataTable.css";

const DataTable = ({
  initEdit,
  dataItems,
  statusChange,
  getData,
  title,
  onClickAddNew,
  handleDelete,
  handleClick,
  banner,
  isItemSelected,
  key,
  labelId,
  headSelected,
  setLastDeleted
}) => {
  const toggleActive = (item) => {
    statusChange({
      id: item.id,
      ...item,
      isactive: item.isactive == 1 ? 0 : 1,
      startDate: null,
    }).then(() => {
      item.isactive = item.isactive == 1 ? 0 : 1;
      getData();
      toaster.success(`${item.nameEN}'s status has been changed successfully.`);
    });
  };
  const handleEdit = (item) => {
    // initEdit(item.id);
    onClickAddNew(`/editBanner/${item.id}`);
  };
  // console.log(initEdit(banner.id),'initEdit');
  useEffect(() => {}, [dataItems]);
  return (
    <React.Fragment>
      <TableRow
        hover
        // role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={key}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, banner.id)}
            role="checkbox"
          />
        </TableCell>
        {headSelected.IntID && <TableCell>{banner.intID}</TableCell>}
        {headSelected.id && <TableCell>{banner.id}</TableCell>}
        {headSelected.NameAR && <TableCell>{banner.nameAR}</TableCell>}
        {headSelected.NameEN && <TableCell>{banner.nameEN}</TableCell>}
        {headSelected.Target && <TableCell>{banner.target}</TableCell>}
        {headSelected.Order && <TableCell>{banner.order}</TableCell>}
        {headSelected.startDate && <TableCell>{banner.startDate}</TableCell>}
        {headSelected.status && (
          <TableCell>
            <Switch
              value={banner.isactive}
              onText={"Active"}
              offText={"OFF"}
              onChange={() => toggleActive(banner)}
            />
          </TableCell>
        )}
        {headSelected.Actions && (
          <TableCell>
            <div className="TableIcons">
              <Link to={`/editBanner/${banner.id}`}>
                <FaRegEdit
                  cursor="pointer"
                  size={24}
                  color="#00AEEF"
                  onClick={() => handleEdit(banner)}
                />
              </Link>
              {handleDelete && (
                <RiDeleteBinLine
                  cursor="pointer"
                  onClick={() =>{ handleDelete(banner.id);setLastDeleted(banner.id)}}
                  color="#ED1650"
                  size={24}
                />
              )}
            </div>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default DataTable;

DataTable.propTypes = {
  initEdit: PropTypes.func,
  dataItems: PropTypes.array,
  statusChange: PropTypes.func,
  getData: PropTypes.func,
  title: PropTypes.string,
  onClickAddNew: PropTypes.func,
  handleDelete: PropTypes.func,
  handleClick: PropTypes.func,
  banner: PropTypes.object,
  isItemSelected: PropTypes.bool,
  key: PropTypes.number,
  labelId: PropTypes.number,
  headSelected: PropTypes.object,
};
