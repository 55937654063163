import PropTypes from "prop-types";
// import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import uploadOnS3 from "../../helpers/uploadOnS3";
import toaster from "../../modules/toaster";
import {
  createCase,
  editSMSPayment,
  updateUrgentCase,
} from "../../reducers/Cases";
import Case from "./Case";
import ExtendedForms from "./ExtendedForms";
import List from "./List";

class UrgentCases extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  nextPath(path) {
    this.context.router.history.push(path);
  }

  render() {
    return (
      <div>
        <Route path={`${this.props.match.url}/list`} component={List} />
        <Route
          path={`${this.props.match.url}/extended-forms`}
          component={ExtendedForms}
        />
        <Route
          path={`${this.props.match.url}/new`}
          render={(props) => {
            return <Case {...props} />;
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProp = (dispatch) => ({
  createCase: (urgentCase) => dispatch(createCase(urgentCase)),
  editSMSPayment: (data) => dispatch(editSMSPayment(data)),
  updateUrgentCase: (data) => dispatch(updateUrgentCase(data)),
});

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.selectedOrg,
    duplicate: state.Cases.duplicate,
    duplicateInit: state.Cases.duplicateInit,
    // formValues: state.form.Case && state.form.Case.registeredFields,
  }),
  mapDispatchToProp
)(UrgentCases);

UrgentCases.propTypes = {
  createCase: PropTypes.func,
  editSMSPayment: PropTypes.func,
  updateUrgentCase: PropTypes.func,
  duplicateInit: PropTypes.object,
  selectedOrg: PropTypes.string,
  formValues: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};
