import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import toaster from "../../modules/toaster";

class Footer extends Component {
  componentDidUpdate() {
    if (this.props.error)
      if (this.props.error.response?.data?.message) {
        toaster.error(this.props.error.response?.data?.message);
      } else {
        toaster.error("Something went wrong, try again later.");
      }
  }

  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul>
              <li>
                <a
                  href="http://jonation.ikhair.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="https://www.appgain.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Company
                </a>
              </li>
            </ul>
          </nav>
          <p className="copyright pull-right">
            © 2023{" "}
            <a
              href="https://jonation.ikhair.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              jonation
            </a>
            , made with love for a better world
          </p>
        </div>
      </footer>
    );
  }
}

export default connect((state) => ({
  error:
    state.ORGs.error ||
    state.Projects.error ||
    state.MNOs.error ||
    state.Activities.error ||
    state.Cases.error,
}))(Footer);

Footer.propTypes = {
  error: PropTypes.object,
};
