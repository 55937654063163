import { appBackendAppgainUrl, getConfigHeaders } from "../api";
import uploadOnS3 from "../helpers/uploadOnS3";

const url = `ngonews`;
const configUrl = `configs`;

const defaultState = {
  ngos: [],
  ngo: {},
  ngoImage: "",
  ngosConfig: {},
  error: null,
  isPending: null,
  loaded: false,
};

const GET_NGOS = "GET_NGOS";
const DELETE_NGO = "DELETE_NGO";
const NGOS_PENDING = "NGOS_PENDING";
const NGO_PENDING = "NGO_PENDING";
const GET_NGO = "GET_NGO";
const NGO_ERROR = "NGO_ERROR";
const NGOS_ERROR = "NGOS_ERROR";
const EDIT_NGO = "EDIT_NGO";
const POST_NGOIMAGE = "POST_NGOIMAGE";
const CREATE_NGO = "CREATE_NGO";
const NGO_CONFIG = "NGO_CONFIG";
const NGO_CONFIG_ERROR = "NGO_CONFIG_ERROR";

// GET inMemory Persons
export const getNgos = () => async (dispatch) => {
  dispatch({
    type: NGOS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );

    dispatch({
      type: GET_NGOS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: NGOS_ERROR,
      payload: error,
    });
  }
};

// GET inMemory Person

export const getNgo = (personId) => async (dispatch) => {
  dispatch({
    type: NGO_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${personId}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_NGO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NGO_ERROR,
      payload: error,
    });
  }
};

export const getInMemoryConfig = () => async (dispatch) => {
  try {
    const data = await appBackendAppgainUrl(
      `${configUrl}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: NGO_CONFIG,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: NGO_CONFIG_ERROR,
      payload: error,
    });
  }
};

// Create ngo
export const createNgo = (newNgoData) => async (dispatch) => {
  dispatch({
    type: NGOS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newNgoData,
      getConfigHeaders()
    );

    dispatch({
      type: CREATE_NGO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NGOS_ERROR,
      payload: error,
    });
  }
};

export const editNgo = (id, newNgoData) => async (dispatch) => {
  dispatch({
    type: NGO_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newNgoData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_NGO,
      payload: { id, newNgoData },
    });
  } catch (error) {
    dispatch({
      type: NGO_ERROR,
      payload: error,
    });
  }
};

// Upload NGO photo
export const uploadNGOImage = (image, NGOId) => async (dispatch) => {
  dispatch({ type: NGOS_PENDING, payload: true });

  try {
    const data = await uploadOnS3(image, `${NGOId}`, "ngonews");
    dispatch({ type: POST_NGOIMAGE, payload: data.Location });
  } catch (error) {
    dispatch({ type: NGOS_ERROR, payload: error });
  }
};

// Delete NGO
export const deleteNgo = (id) => async (dispatch) => {
  dispatch({
    type: NGOS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());

    dispatch({
      type: DELETE_NGO,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: NGOS_ERROR,
      payload: error,
    });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case NGO_PENDING:
    case NGOS_PENDING:
      return { ...state, isPending: true };

    case GET_NGOS:
      return {
        ...state,
        ngos: action.payload,
        loaded: true,
        isPending: false,
      };

    case CREATE_NGO:
      return {
        ngos: [...state.ngos, action.payload],
        isPending: false,
        loaded: true,
      };

    case EDIT_NGO:
      return {
        ...state,
        ngos: state.ngos?.map((ngo) =>
          ngo.objectId === action.payload.id ? action.payload.newNgoData : ngo
        ),
        isPending: false,
        loaded: true,
      };

    case POST_NGOIMAGE:
      return {
        ...state,
        ngoImage: action.payload,
        isPending: false,
        loaded: true,
      };

    case GET_NGO:
      return {
        ...state,
        ngo: action.payload,
        loaded: true,
        isPending: false,
      };
    case DELETE_NGO:
      return {
        ...state,
        ngos: state.ngos.filter((person) => person.objectId !== action.payload),
        loaded: true,
        isPending: false,
      };
    default:
      return { ...state };
  }
}
