import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import format from "date-fns/format";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import toaster from "../../modules/toaster";
import { buildData, getData, stableSortReports } from "../../reducers/Reports";

const Reports = ({
  orgWithId,
  getReports,
  buildReports,
  reports,
  sortReports,
  error,
  msg,
}) => {
  const headCells = [
    { id: "Keyword", numeric: false, disablePadding: true, label: "Keyword" },
    {
      id: "count",
      numeric: true,
      disablePadding: false,
      label: "Total Messages",
    },
    { id: "Etisalat", numeric: true, disablePadding: false, label: "Etisalat" },
    {
      id: "Du",
      numeric: true,
      disablePadding: false,
      label: "Du Messages",
    },
    {
      id: "Uniquecount",
      numeric: true,
      disablePadding: false,
      label: "Unique Messages",
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "Total Donations",
    },
  ];

  const useStyles = makeStyles(() => ({
    table: {
      minWidth: 650,
    },
    columns: {
      fontWeight: "bold",
      fontSize: "1.5rem",
    },
    formControl: {
      minWidth: 120,
    },
    dayPicker: {
      display: "flex",
    },
    getDataBtn: {
      marginRight: "1rem",
    },
    buildDataBtn: {
      backgroundColor: "#6D71EF",
      "&:hover": {
        backgroundColor: "#363ad1",
        color: "white",
      },
    },
    exportDataBtn: {
      marginLeft: "1rem",
      backgroundColor: "#34be86",
      "&:hover": {
        backgroundColor: "#0b7c4e",
        color: "white",
      },
    },
    buttonGrid: {
      marginBottom: "1rem",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }));

  const classes = useStyles();
  const [organizationID, setOrganizationID] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [keyword, setKeyword] = useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Total Donations");
  const handleSelectChange = (e) => {
    setSelectedOrg(e.target.value);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    sortReports(order, orderBy);
  };

  useEffect(() => {
    setOrganizationID(orgWithId);
  }, [organizationID, reports]);

  useEffect(() => {
    if (error && msg !== "") {
      toaster.error(msg);
    } else {
      if (msg !== "") {
        toaster.success(msg);
      }
    }
  }, [error, msg]);
  const getDataOnClick = () => {
    if (keyword !== "") {
      getReports({
        orgID: selectedOrg,
        startDate: format(selectedDate.startDate, "yyyy-MM-dd"),
        endDate: format(selectedDate.endDate, "yyyy-MM-dd"),
        keyword,
      });
    } else {
      getReports({
        orgID: selectedOrg,
        startDate: format(selectedDate.startDate, "yyyy-MM-dd"),
        endDate: format(selectedDate.endDate, "yyyy-MM-dd"),
      });
    }
  };
  const buildDataOnClick = () => {
    buildReports({
      orgID: selectedOrg,
      startDate: format(selectedDate.startDate, "yyyy-MM-dd"),
      endDate: format(selectedDate.endDate, "yyyy-MM-dd"),
    })
      .then(() => {
        toaster.success("Please Wait while we are building your Reports");
      })
      .catch(() => {
        toaster.error("Error Building Reports");
      });
  };
  return (
    <div>
      <Container maxWidth="lg">
        <form>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={20}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">
                Organization
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={selectedOrg}
                onChange={handleSelectChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {_.map(organizationID, (org) => (
                  <MenuItem value={org.takamul_ngo_id}>{org.nameAR}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="standard-basic"
              label="Keyword"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                xs={12}
                sm={6}
                justifyContent="space-around"
                className={classes.dayPicker}
              >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="StartDate"
                  label="Start Date"
                  value={selectedDate.startDate}
                  onChange={(e) =>
                    setSelectedDate({ ...selectedDate, startDate: e })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="endDate"
                  label="End Date"
                  value={selectedDate.endDate}
                  onChange={(e) =>
                    setSelectedDate({ ...selectedDate, endDate: e })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid className={classes.buttonGrid} xs={12}>
              <Button
                className={classes.getDataBtn}
                variant="contained"
                color="primary"
                onClick={getDataOnClick}
              >
                Get Data
              </Button>
              <Button
                className={classes.buildDataBtn}
                onClick={buildDataOnClick}
                variant="contained"
              >
                Build Data
              </Button>
              {reports.length > 0 && (
                <Button
                  variant="contained"
                  className={classes.exportDataBtn}
                  color="secondary"
                >
                  <CSVLink data={reports} filename="reports.csv">
                    Download CSV
                  </CSVLink>
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Container>
      <TableContainer component={Paper}>
        <Table className={classes.table} arial-label="Table">
          <TableHead>
            <TableRow>
              {headCells?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align="center"
                  padding={headCell.disablePadding ? "none" : "normal"}
                  className={classes.columns}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reports?.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell align="center">{row.keyword}</TableCell>
                <TableCell align="center">{row.count}</TableCell>
                <TableCell align="center">{row.Etisalat}</TableCell>
                <TableCell align="center">{row.Du}</TableCell>
                <TableCell align="center">{row.Uniquecount}</TableCell>
                <TableCell align="center">{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default connect(
  (state) => ({
    orgs: state.ORGs.orgs,
    orgWithId: state.ORGs.orgWithId,
    reports: state.Reports.reports,
    error: state.Reports.error,
    msg: state.Reports.msg,
  }),
  (dispatch) => ({
    getReports: (data) => dispatch(getData(data)),
    sortReports: (order, orderBy) =>
      dispatch(stableSortReports(order, orderBy)),
    buildReports: (data) => dispatch(buildData(data)),
  })
)(Reports);

Reports.propTypes = {
  orgWithId: PropTypes.array,
  getReports: PropTypes.func,
  buildReports: PropTypes.func,
  reports: PropTypes.array,
  sortReports: PropTypes.func,
  error: PropTypes.bool,
  msg: PropTypes.string,
};
