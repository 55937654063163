// import { Pagination } from "antd";
import { Pagination, PaginationItem } from "@material-ui/lab";

import "assets/styles/base.scss";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NewDataTable from "../../components/NewDataTable";
import OrganizationsDataTable from "../../components/OrganizationsDataTable/OrganizationsDataTable";
import toaster from "../../modules/toaster";
import { deleteOrgs, loadORGsList } from "../../reducers/ORGs";

const selecttableHead = [
  "id",
  "intID",
  "nameAR",
  "nameEN",
  "infoAR",
  "infoEN",
  "order",
  "ReportingEmail",
  "address",
  "countryid",
  "email",
  "fax",
  "fb",
  "tel",
  "tw",
  "web",
  "hasRepeatedDonation",
  "hasReporting",
  "paymentMethods",
  "status",
  "Actions",
];


const rowsPerPage = 10;

const Organizations = ({ isPending, allORGs, loadAllORGs, history,deleteOrgs }) => {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    id: false,
    intID: false,
    nameAR: true,
    nameEN: false,
    infoAR: false,
    infoEN: false,
    order: false,
    ReportingEmail: false,
    address: false,
    countryid: false,
    email: false,
    fax: false,
    fb: false,
    tel: false,
    tw: false,
    web: false,
    hasRepeatedDonation: false,
    hasReporting: false,
    paymentMethods: false,
    status: true,
    Actions: true,
  });
  const [organizationsIDs, setOrganizationsIDs] = useState({
    organizationsIDs: selected,
  });
  const [lastDeleted, setLastDeleted] = useState();

  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setOrganizationsIDs({ organizationsIDs: newSelected });

  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    loadAllORGs();
  }, [lastDeleted]);
  useEffect(() => {
    loadAllORGs();
  });
 
  const addNewOrganization = () => {
    history.push("addNewOrganization");
  };
  const handleDelete = () => {
    if(organizationsIDs.organizationsIDs.length===0){
      toaster.warning('please select some organizations')
    }else{
      deleteOrgs(organizationsIDs).then(() => {
        setLastDeleted(organizationsIDs);
        // loadCases(selectedOrg);
    });
    setSelected([])
    }
}
  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <div className="header">
        <h4 className="title">Organizations</h4>
      </div>
      <div className="card">
        {allORGs.length > 0 ? (
          <NewDataTable
            title={"Add an Organization"}
            csvTitle={"Organization"}
            add={addNewOrganization}
            data={allORGs}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
              <Pagination 
              // size="small" 
              page={page}
              count={Math.ceil(allORGs.length/10)}
              // total={projects.length} 
              onChange={handlePaginationChange} />
            }
          >
            {allORGs
              ?.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
           

                  <OrganizationsDataTable
                    key={row.id}
                    data={allORGs}
                    org={row}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    handleClick={handleClick}
                    isPending={isPending}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
          <p style={{ textAlign: "center" }}>No organization Found</p>
          <button
            className="addButton"
            onClick={() => {
              addNewOrganization
            }}
          >
            <BsPlus size={24} />
            Add A new organization
          </button>
        </div>        )}
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    isPending: state.ORGs.isPending,
    allORGs: state.ORGs.orgs,
  }),
  (dispatch) => ({
    loadAllORGs: () => dispatch(loadORGsList()),
    deleteOrgs:(organizationsIDs)=> dispatch(deleteOrgs(organizationsIDs))

  })
)(Organizations);

Organizations.propTypes = {
  isPending: PropTypes.bool,
  allORGs: PropTypes.array,
  loadAllORGs: PropTypes.func,
  history: PropTypes.object,
};
