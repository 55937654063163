import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import InMemoryDataTable from "../../components/InMemoryDataTable/InMemoryDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  approveInMemoryPerson,
  deleteInMemoryPerson,
  getInMemoryConfig,
  getInMemoryPersons,
} from "../../reducers/InMemory";
import "./inMemory.css";

import "../../assets/styles/base.scss";
import NewDataTable from "../../components/NewDataTable";
import { BsPlus } from "react-icons/bs";
// import { Pagination } from "antd";
import { Pagination, PaginationItem } from "@material-ui/lab";
import toaster from "../../modules/toaster";
import { fireAutomator } from "../../api";

const selecttableHead = [
  "UserId",
  "Image",
  "Name",
  "Age",
  "applicantName",
  "collectedAmount",
  "targetAmount",
  "Mobile",
  "Deeplink",
  "Description",
  "CreatedAt",
  "OrgId",
  "ActId",
  "sdl",
  "Approved",
  "Actions",
];

const rowsPerPage = 10;

const InMemory = ({
  inMemoryPersons,
  inMemoryPersonsConfig,
  getInMemoryPersons,
  getConfig,
  approveInMemoryPerson,
  deleteInMemoryPerson,
  isPending,
  history,
}) => {
  const [apiKey, setApiKey] = useState("");
  const [projectId, setProjectId] = useState("");
  const [sortedPersons, setSortedPersons] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    UserId: true,
    Image: true,
    Name: true,
    Age: true,
    applicantName: false,
    collectedAmount: false,
    targetAmount: false,
    Mobile: false,
    Deeplink: true,
    Description: false,
    CreatedAt: false,
    OrgId: false,
    ActId: false,
    sdl: false,
    Approved: true,
    Actions: true,
  });
  useEffect(() => {
    getInMemoryPersons();
    getConfig();
  }, []);

  useEffect(() => {
    const temp = [...inMemoryPersons];
    setSortedPersons(
      temp.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    );
  }, [inMemoryPersons]);

  useEffect(() => {
    if (!_.isEmpty(inMemoryPersonsConfig)) {
      setApiKey(inMemoryPersonsConfig.find((el) => el.key == "apiKey").value);
      setProjectId(
        inMemoryPersonsConfig.find((el) => el.key == "projectId").value
      );
    }
  }, [inMemoryPersonsConfig]);

  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };
  const handleDelete = () => {
    if (selected.length === 0) {
      toaster.warning("please select some cases");
    } else {
      Promise.allSettled(
        selected.map((e) => {
          return deleteInMemoryPerson(e);
        })
      ).then((responseArr) => {
        responseArr.forEach((res) => {
          console.log(res);
        });
      });
      setSelected([])

    }
  };
  const handleAddCase = () => {
    history.push("addInMemory");
  };

  // if (isPending) {
  //   return <LoadingSpinner />;
  // }
  const sortedPersonsByActive = sortedPersons.sort(
    (a, b) => Number(b.Approved) - Number(a.Approved)
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <div className="header">
        <h4 className="title">In Memory</h4>
      </div>
      <div className="card">
        {sortedPersonsByActive.length > 0 ? (
          <NewDataTable
            title={"Add New Case"}
            csvTitle={"In Memory"}
            add={handleAddCase}
            data={sortedPersonsByActive}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
              <Pagination
                // size="small"
                page={page}
                count={Math.ceil(sortedPersonsByActive.length / 10)}
                // total={projects.length}
                onChange={handlePaginationChange}
              />
            }
          >
            {sortedPersonsByActive
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.objectId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <InMemoryDataTable
                    key={row.objectId}
                    person={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    approveInMemoryPerson={approveInMemoryPerson}
                    deleteInMemoryPerson={deleteInMemoryPerson}
                    headSelected={headSelected}
                    data={sortedPersonsByActive}
                    apiKey={apiKey}
                    projectId={projectId}
                    isPending={isPending}
                    
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
            <p style={{ textAlign: "center" }}>No Cases Found</p>
            <button
              className="addButton"
              onClick={() => {
                handleAddCase;
              }}
            >
              <BsPlus size={24} />
              Add A new case
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    inMemoryPersons: state.InMemory.inMemoryPersons,
    inMemoryPersonsConfig: state.InMemory.inMemoryPersonsConfig,
    isPending: state.InMemory.isPending,
    inMemoryPerson: state.InMemory.inMemoryPerson,
  }),
  (dispatch) => ({
    getInMemoryPersons: () => dispatch(getInMemoryPersons()),
    deleteInMemoryPerson: (id) => dispatch(deleteInMemoryPerson(id)),
    approveInMemoryPerson: (id, body) =>
      dispatch(approveInMemoryPerson(id, body)),
    getConfig: () => dispatch(getInMemoryConfig()),
  })
)(InMemory);
