import networkService from "../api";
import _ from "lodash";
const defaultState = {
  reports: [],
  isPending: false,
  msg: "",
  error: false,
};
const GET_REPORTS = "GET_REPORTS";
const BUILD_REPORTS = "BUILD_REPORTS";
const SORT_REPORTS = "SORT_REPORTS";
const REPORTS_PENDING = "REPORTS_PENDING";
const GET_KEYWORD_REPORTS = "GET_KEYWORD_REPORTS";
const GETREPORTS_ERROR = "GETREPORTS_ERROR";

export const getData = (data) => async (dispatch) => {
  dispatch({
    type: REPORTS_PENDING,
  });
  try {
    if (data.keyword) {
      let res = await networkService.send("keywordReport", data);

      dispatch({
        type: GET_KEYWORD_REPORTS,
        payload: res,
        keyword: data.keyword,
      });
    } else {
      let res = await networkService.send("getReport", data);
      dispatch({
        type: GET_REPORTS,
        payload: res,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: GETREPORTS_ERROR,
    });
  }
};
export const buildData = (data) => async (dispatch) => {
  dispatch({
    type: REPORTS_PENDING,
  });
  try {
    await networkService.send("buildReports", data);
    dispatch({
      type: BUILD_REPORTS,
      payload: "Please wait while we build your report",
    });
  } catch (err) {
    console.error(err);
  }
};

export const stableSortReports = (order, orderBy) => (dispatch) => {
  dispatch({
    type: "SORT_PENDING",
  });

  dispatch({
    type: SORT_REPORTS,
    payload: {
      order,
      orderBy,
    },
  });
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        reports: action.payload.data.results,
        isPending: false,
        error: false,
        msg: "Reports fetched successfully",
      };
    case GET_KEYWORD_REPORTS:
      return {
        ...state,
        reports: [action.payload.data],
        isPending: false,
      };
    case BUILD_REPORTS:
      return {
        ...state,
        msg: action.payload,
        isPending: false,
      };
    case SORT_REPORTS:
      return {
        ...state,
        reports: _.orderBy(
          state.reports,
          [action.payload.orderBy],
          [action.payload.order]
        ),
        pending: false,
      };
    case REPORTS_PENDING:
      return {
        ...state,
        isPending: true,
      };

    case GETREPORTS_ERROR:
      return {
        ...state,
        isPending: false,
        error: true,
        reports: [],
        msg: "Please Build Data First",
      };
    default:
      return state;
  }
}
