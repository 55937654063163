import networkService from "../api.js";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner.js";
import toaster from "../modules/toaster.js";
import React from "react"
const defaultState = {
  projects: [],
  error: null,
  isPending: null,
  loaded: false,
  duplicate: null,
  duplicateInit: null,
  msg: "",
};

const PROJECTS_DUPLICATE = "PROJECTS_DUPLICATE";

const CREATE_PROJECT = "CREATE_PROJECT";
const UPDATE_PROJECT = "UPDATE_PROJECT";
const EDIT_PROJECT = "EDIT_PROJECT";
const LOAD_PROJECTS_LIST = "LOAD_PROJECTS_LIST";
const PROJECTS_PENDING = "PROJECTS_PENDING";
const PROJECTS_ERROR = "PROJECTS_ERROR";
const DELETE_PROJECT = "DELETE_PROJECT"

export const loadProjectsList = (orgId) => (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  let data = {};

  if (orgId && orgId != "") data.org_id = orgId;
  networkService
    .send("projects", data)
    .then((res) => {
      dispatch({
        type: LOAD_PROJECTS_LIST,
        projects: res.data.projects,
      });
    })
    .catch((error) => {
      dispatch({ type: PROJECTS_ERROR, error });
    });
};

export const createProject = (project) => async (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  try {
    await networkService.send("project", project);
    dispatch({
      type: CREATE_PROJECT,
    });
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR, error });
  }
};
export const deleteProject = (projectsID) => async (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  try {
    await networkService.send("deleteprojects", projectsID);
    dispatch({
      type: DELETE_PROJECT,
    });
    toaster.success('successfully deleted projects')
  } catch (error) {
    toaster.error(error)

    dispatch({ type: PROJECTS_ERROR, error });
  }
};
export const duplicateProject = (project) => async (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  try {
    let res = await networkService.send("getProject", { id: project.id });

    dispatch({
      type: PROJECTS_DUPLICATE,
      project: res.data.project,
    });
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR, error });
  }
};

export const editSMSPayment = (data) => async (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  try {
    await networkService.send("updateSMSPayment", data);
    dispatch({
      type: EDIT_PROJECT,
    });
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR, error });
  }
};
export const editBankPayment = (data) => async (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  try {
    await networkService.send("updateBankPayment", data);
    dispatch({
      type: EDIT_PROJECT,
    });
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR, error });
  }
};
// updateCCPayment
export const editCCPayment = (data) => async (dispatch) => {

  dispatch({ type: PROJECTS_PENDING });
  try {
    await networkService.send("updateCCPayment", data,);
    dispatch({
      type: EDIT_PROJECT,
    });
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR, error });
  }
};
export const updateProjects = () => async (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  try {
    await networkService.send("updateProjects");
    dispatch({
      type: UPDATE_PROJECT,
      payload: "Projects Updated",    
    });
    <LoadingSpinner/>
    toaster.success('Projects Updated')
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR, error });
  }
};

export const editProjectStatus = (data) => async (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  try {
    await networkService.send("projectStatus", data);
    dispatch({
      type: EDIT_PROJECT,
    });
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR, error });
  }
};

export const editProject = (data) => async (dispatch) => {
  dispatch({ type: PROJECTS_PENDING });
  try {
    await networkService.send("editProject", data);
    dispatch({
      type: EDIT_PROJECT,
    });
  } catch (error) {
    dispatch({ type: PROJECTS_ERROR, error });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_PROJECTS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        projects: action.projects,
        duplicate: null,
      };

    case PROJECTS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case PROJECTS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        projects: [],
        duplicate: null,
      };

    case CREATE_PROJECT:
      return {
        ...state,
        duplicate: null,
        isPending: false,
        error: false,
      };
      case DELETE_PROJECT:
        return {
          ...state,
          delete: null,
          isPending: false,
          error: false,
        };
    case EDIT_PROJECT:
      return {
        ...state,
        isPending: false,
        duplicate: null,
        error: false,
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        msg: action.payload,
        isPending: false,
        error: false,
      };

    case PROJECTS_DUPLICATE:
      return {
        ...state,
        duplicate: {
          ...action.project,
          sms_payment: action.project.sms_payment
            ? [...action.project.sms_payment]
            : [],
        },
        duplicateInit: {
          ...action.project,
          sms_payment: action.project.sms_payment
            ? [...action.project.sms_payment]
            : [],
        },
        isPending: false,
        error: false,
      };
    default:
      return state;
  }
}
