import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";

const KeywordDataTable = ({
  keyword,
  handleClick,
  isItemSelected,
  labelId,
  getOrgName,
  onClickEdit,
  onClickDelete,
  orgWithId,
  headSelected,
}) => {
  return (
    <TableRow
      key={keyword.objectId}
      hover
 
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
             onClick={(event) => handleClick(event, keyword.objectId)}
             role="checkbox"
          checked={isItemSelected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell>

      {headSelected.Title && <TableCell>{keyword.title}</TableCell>}
      {headSelected.CreatedAt && (
        <TableCell>{moment(keyword.createdAt).format("DD/MM/YYYY")}</TableCell>
      )}
      {headSelected.UpdatedAt && (
        <TableCell>{moment(keyword.updatedAt).format("DD/MM/YYYY")}</TableCell>
      )}
      {headSelected.organizationName && (
        <TableCell align="left">
          {getOrgName(orgWithId, keyword.org_id)}
        </TableCell>
      )}
      {headSelected.Actions && (
        <TableCell>
          <div className="TableIcons">

          <FaRegEdit
            onClick={() => onClickEdit(keyword.objectId)}
            size={24}
            color="#00AEEF"
            cursor="pointer"
          />
          <RiDeleteBinLine
            cursor="pointer"
            onClick={() => onClickDelete(keyword.objectId)}
            color="#ED1650"
            size={24}
          />
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};
export default KeywordDataTable;
