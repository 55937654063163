import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  createNewSelectedVideo,
  editSelectedVideo,
  getSingleVideo,
} from "../../reducers/SelectedVideos";

const AddNewVideo = ({
  singleSelectedVideo,
  isPending,
  getSingleSelectedVideo,
  editSelectedVideo,
  createNewSelectedVideo,
  history,
}) => {
  const selectedVideoId =
    window.location.hash.includes("editSelectedVideo") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [url, setUrl] = useState("");

  const submitSelectedVideoDataHandler = (e) => {
    e.preventDefault();

    const selectedVideoData = {
      url,
    };

    if (isEditingMode) {
      editSelectedVideo(selectedVideoId, selectedVideoData);
    } else {
      createNewSelectedVideo(selectedVideoData);
    }
    history.push("/selectedVideos");
  };

  useEffect(() => {
    if (selectedVideoId) {
      getSingleSelectedVideo(selectedVideoId);
      setIsEditingMode(true);
      setUrl(singleSelectedVideo.url);
    } else {
      setIsEditingMode(false);
    }
  }, [selectedVideoId, singleSelectedVideo.url]);

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <div className="header">
        <h4 className="title">
          {isEditingMode
            ? `Edit SelectedVideo: ${singleSelectedVideo.name}`
            : "Add New SelectedVideo"}
        </h4>
      </div>
      <form
        className="card"
        style={{ minHeight: "auto", padding: "12px 24px" }}
        onSubmit={submitSelectedVideoDataHandler}
      >
        <FormGroup>
          <div className="fieldDiv">
            <label id="url">URL</label>
            <FormControl
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              name="url"
              htmlFor="url"
              placeholder="Enter URL"
              required
            />
          </div>
        </FormGroup>

        <div className="submit">
          <Button className="create-edit-form-btn" type="submit">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default connect(
  (state) => ({
    singleSelectedVideo: state.SelectedVideos.singleVideo,
    isPending: state.SelectedVideos.isPending,
  }),
  (dispatch) => ({
    getSingleSelectedVideo: (id) => dispatch(getSingleVideo(id)),
    editSelectedVideo: (id, data) => dispatch(editSelectedVideo(id, data)),
    createNewSelectedVideo: (data) => dispatch(createNewSelectedVideo(data)),
  })
)(AddNewVideo);

AddNewVideo.propTypes = {
  singleSelectedVideo: PropTypes.object,
  isPending: PropTypes.bool,
  getSingleSelectedVideo: PropTypes.func,
  editSelectedVideo: PropTypes.func,
  createNewSelectedVideo: PropTypes.func,
  history: PropTypes.object,
};
