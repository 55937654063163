import defaultImg from "assets/images/defaultImg.svg";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  createTeam,
  editTeam,
  getTeam,
  uploadTeamLogo,
} from "../../reducers/Teams";
import "./AddTeams.css";
import toaster from "../../modules/toaster";

const AddTeam = ({
  history,
  isPending,
  team,
  getTeam,
  createTeam,
  editTeam,
  uploadLogo,
  teamLogo,
}) => {
  const teamId =
    window.location.hash.includes("editTeam") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [edit, setEdit] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");
  const [show, setShow] = useState(false);
  const fileInputRef = useRef();

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  useEffect(() => {
    if (teamId) {
      getTeam(teamId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [teamId]);

  useEffect(() => {
    if (selectedLogo) {
      if (selectedLogo.size > 512000) {
        setShow(true);
        selectedLogo("");
      }
    }
    if (teamLogo) {
      setSelectedLogoUrl(teamLogo);
    }
  }, [selectedLogo, teamLogo]);

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedLogo(e.target.files[0]);
    const file = e.target.files[0];
    setImage(file);
  };

  const submitTeamHandler = (e) => {
    e.preventDefault();
    if(!image &&!isEditingMode){
      toaster.error('please upload image')

    }else{

      const teamData = {
        Name: name,
        Title: title,
        Logo: selectedLogoUrl,
      };
  
      if (isEditingMode) {
        editTeam(teamId, teamData);
      } else {
        createTeam(teamData);
      }
      // wipeAmbassadorImageData();
      history.push("/teams");
    }
    
  };

  useEffect(() => {
    if (!!team && teamId) {
      setName(team.Name);
      setTitle(team.Title);
      setSelectedLogoUrl(team.Logo);
      setEdit(true);
      setPreview(team.Logo)
    }
  }, [team]);

  const uploadLogoHandler = () => {
    uploadLogo(selectedLogo, name);
    setEdit(true);
  };

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <div className="header">
        <h4 className="title">
          {" "}
          {isEditingMode ? `Edit Team: ${name}` : "Add New Team"}
        </h4>
      </div>
      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={submitTeamHandler}
      >
        <FormGroup>
          <div className="fieldDiv  form-group">
            <label id="teamName" className="control-label">Name</label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="teamName"
              htmlFor="teamName"
              placeholder="Enter Name"
              required
            />
          </div>

          <div className="fieldDiv form-group">
            <label id="title" className="control-label">Title</label>
            <FormControl
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              name="teamTitle"
              htmlFor="teamTitle"
              placeholder="Enter Title"
              required
            />
          </div>

          <div className="fieldDiv form-group">
            <label className="control-label">
              {isEditingMode ? "Change" : "Upload"} Photo
              <img
                src={preview || defaultImg}
                // src={preview || imageURL}
                alt="default"
                style={{ width: "124px", height: "124px", display: "block" }}
              />{" "}
            </label>
            <div className="file-input">
              {/* SVG support */}
              <input
                style={{ width: "48px" }}
                type="file"
                accept="image/*"
                placeholder="Choose Team Logo"
                onChange={storeImageHandler}
                className="file"
                // required={isEditingMode? false:true}
                id="file"
                ref={fileInputRef}
              />
              <button
                htmlFor="file"
                className="uploadBtn"
                onClick={(event) => {
                  event.preventDefault();
                  fileInputRef.current.click();
                }}
              >
                {selectedLogo || isEditingMode ? "Change " : "Select "}
              </button>
              {/* <p className="file-name"></p> */}
              <button
                className="removeBtn"
                type="button"
                onClick={() => {
                  setSelectedLogo(null);
                  setPreview(null);
                  // setImageDimensions({
                  //   width: 0,
                  //   height: 0,
                  // });
                }}
              >
                Remove
              </button>
            </div>
            <div style={{ marginTop: "20px" }}>
              {selectedLogo && (
                
                <React.Fragment>
                  {!selectedLogoUrl&&<p>Please Upload the image</p>}
              
              <button
              type="button"
              style={{marginTop:".4em"}}
                onClick={uploadLogoHandler}
                className="uploadBtn"
                >
                    Upload photo
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </FormGroup>
        <div className="submit">
          <Button
            // disabled={!edit}
            className="create-edit-form-btn"
            type="submit"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
        {show && (
          <ConfirmModal
            handleClose={() => setShow(false)}
            handleShow={() => setShow(true)}
            message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
            setShow={setShow}
            show={show}
          ></ConfirmModal>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Teams.isPending,
  team: state.Teams.team,
  teamLogo: state.Teams.teamLogo,
});

const actions = (dispatch) => ({
  getTeam: (teamId) => dispatch(getTeam(teamId)),
  createTeam: (newTeamData) => dispatch(createTeam(newTeamData)),
  editTeam: (teamId, newTeamData) => dispatch(editTeam(teamId, newTeamData)),
  uploadLogo: (image, id) => dispatch(uploadTeamLogo(image, id)),
});

export default connect(mapStateToProps, actions)(AddTeam);

AddTeam.propTypes = {
  isPending: PropTypes.bool,
  team: PropTypes.object,
  getTeam: PropTypes.func,
  createTeam: PropTypes.func,
  editTeam: PropTypes.func,
  uploadLogo: PropTypes.func,
  teamLogo: PropTypes.string,
  history: PropTypes.object,
};
