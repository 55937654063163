import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, ToggleButton } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

import {
  createInMemoryPerson,
  editInMemoryPerson,
  getInMemoryPerson,
  uploadPersonPhoto,
} from "../../reducers/InMemory.js";
import "./AddInMemoryOf.css";

const AddInMemoryOf = ({
  history,
  isPending,
  inMemoryPerson,
  inMemoryPersonImage,
  getInMemoryPerson,
  editInMemoryPerson,
  createInMemoryPerson,
  uploadPersonPhoto,
  activities,
  orgs,
}) => {
  const personId =
    window.location.hash.includes("editInMemory") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState("");
  const [share, setShare] = useState('');

  const [age, setAge] = useState(0);
  const [actId, setActId] = useState("");
  const [orgId, setOrgId] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [selectedImage, setSelectedImage] = useState("");
  const [imageURL, setImageURL] = useState(
    "https://cdn.appgain.io/5ce15857bbab6b001018eed0/ikharstga/hC2L7.jpg"
  );
  const [applicantName, setApplicantName] = useState("");
  const [userId, setUserId] = useState("");
  const [language, setLanguage] = useState("AR");
  const [gender, setGender] = useState("male");

  const [isApproved, setIsApproved] = useState(false);
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(true);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openGender, setOpenGender] = useState(false);

  const [openAct, setOpenAct] = useState(false);
  const [openOrg, setOpenOrg] = useState(false);
  const [error, setError] = useState("");

  const fileInputRef = useRef();

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [targetAmount,setTargetAmount]=useState(0)

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedImage(e.target.files[0]);
    const file = e.target.files[0];

    setImage(file);
  };
  //
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);
  // Upload Image
  const uploadImageHandler = () => {
    const fd = new FormData();
    fd.append("photo", selectedImage);
    uploadPersonPhoto(fd);
    setEdit(true);
  };

  useEffect(() => {
    if (personId) {
      getInMemoryPerson(personId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [personId]);

  const submitInMemoryPersonDataHandler = (e) => {
    e.preventDefault();

    if (description.getCurrentContent().getPlainText().trim().length <= 0) {
      setIsDescriptionRequired(true);
      return;
    }
    const inMemoryPersonData = {
      name,
      description: stateToHTML(description.getCurrentContent()),
      image: imageURL,
      age: parseInt(age),
      actId,
      orgId,
      sdl:share,
      userId,
      Approved: isApproved,
      language,
      gender,
      applicantName,
      targetAmount:Number(targetAmount),
    };

    // if (!isEditingMode) {
    //   inMemoryPersonData['actId'] = actId;
    //   inMemoryPersonData['orgId'] = orgId;
    //   inMemoryPersonData['userId'] = _.isEmpty(userId) ? '0' : userId;
    //   inMemoryPersonData['Approved'] = isApproved;
    //   inMemoryPersonData['language'] = language;
    //   inMemoryPersonData['applicantName'] = applicantName;
    // }

    if (isEditingMode) {
      editInMemoryPerson(personId, inMemoryPersonData);
    }
    if (!isEditingMode && !_.isEmpty(actId) && !_.isEmpty(orgId)) {
      createInMemoryPerson(inMemoryPersonData);
    } else if (!isEditingMode && (_.isEmpty(actId) || _.isEmpty(orgId))) {
      setError("Act id and Organization id are required");
      return;
    }

    // wipeAmbassadorImageData();
    history.push("/inMemory");
  };

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage.size > 512000) {
        setShow(true);
        setSelectedImage("");
      }
    }
    if (inMemoryPersonImage) {
      setImageURL(inMemoryPersonImage);
    } else if (!inMemoryPersonImage & (gender === "female")) {
      setImageURL(
        "https://cdn.appgain.io/5ce15857bbab6b001018eed0/ikharstga/tNQup.jpg"
      );
    } else if (!inMemoryPersonImage & (gender === "male")) {
      setImageURL(
        "https://cdn.appgain.io/5ce15857bbab6b001018eed0/ikharstga/hC2L7.jpg"
      );
    }
  }, [selectedImage, inMemoryPersonImage, gender]);

  useEffect(() => {
    if (!isPending&&inMemoryPerson && personId) {
      setName(inMemoryPerson.name);

      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              inMemoryPerson.description
                ? inMemoryPerson.description
                : `<p>No Description</p>`
            )
          )
        )
      );
      setImageURL(inMemoryPerson.image);
      setAge(inMemoryPerson.age);
      setUserId(inMemoryPerson.userId);
      setGender(inMemoryPerson.gender);
   setShare(inMemoryPerson.share)      
      if (inMemoryPerson.language){

        setLanguage(inMemoryPerson.language);
        setActId(inMemoryPerson.actId);
        setOrgId(inMemoryPerson.orgId);
      }
      setIsApproved(inMemoryPerson.Approved);
      setApplicantName(inMemoryPerson.applicantName);
      setTargetAmount(inMemoryPerson.targetAmount)
      setEdit(true);
    }
  }, [inMemoryPerson,inMemoryPerson.actId,inMemoryPerson.orgId,inMemoryPerson.gender]);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleActIdChange = (e) => {
    setActId(e.target.value);
  };

  const handleOrgIdChange = (e) => {
    setOrgId(e.target.value);
  };

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
     
        <div className="header">
          <h4 className="title">
          {isEditingMode
          ? `Edit inMemoryPerson: ${name}`
          : "Add New inMemoryPerson"}
          </h4>
        </div>
      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={submitInMemoryPersonDataHandler}
      >
        <Grid container spacing={3}  style={{ padding: "15px 28px" }}>
          <Grid item md={6} sm={12} className="form-group">
            <label id="personId" className="control-label">
              Person id(optional)
            </label>

            <FormControl
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              name="personId"
              htmlFor="personId"
              placeholder="Enter id"
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label id="personName" className="control-label">
              Person Name
            </label>

            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="personName"
              htmlFor="personName"
              placeholder="Enter Name"
              required
            />
          </Grid>
      

          <Grid item md={6} sm={12} className="form-group">
          <label id="gender" className="control-label">
              Gender
            </label>
            <Select
              className="newProjectSelect"
              labelId="gender"
              id="gender"
              open={openGender}
              onClose={() => setOpenGender(false)}
              onOpen={() => setOpenGender(true)}
              value={gender}
              onChange={handleGenderChange}
              style={{ padding: "8px 10px" }}
            >
              <MenuItem value="male">male</MenuItem>
              <MenuItem value="female">female</MenuItem>
       </Select>
          </Grid>
        
            <Grid item md={6} sm={12} className="form-group">
            <label id="personAge" className="control-label">
              Person Age
            </label>
            <FormControl
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              name="personAge"
              htmlFor="personAge"
              placeholder="Enter Age"
              required
            />
            </Grid>
            
          <Grid item sm={12} className="form-group">
            <label id="personDescription" className="control-label">
              Description
            </label>

            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setDescription(e)}
              onFocus={() => setIsDescriptionRequired(false)}
              onBlur={() =>
                description.getCurrentContent().getPlainText().trim().length <=
                  0 && setIsDescriptionRequired(true)
              }
            />

            {isDescriptionRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Description field is required
              </p>
            )}
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={3}>

            <Grid item md={4} sm={6} className="form-group">
              <label id="language" className="control-label">
                Language
              </label>

              <Select
                className="newProjectSelect"
                labelId="language"
                id="languages"
                open={openLanguage}
                onClose={() => setOpenLanguage(false)}
                onOpen={() => setOpenLanguage(true)}
                value={language}
                onChange={handleLanguageChange}
                style={{ padding: "8px 10px" }}
              >
                <MenuItem value="AR">ar</MenuItem>
                <MenuItem value="EN">en</MenuItem>
              </Select>
            </Grid>
            <Grid item md={4} sm={6} className="form-group">
              <label id="actId" className="control-label">
                Act id
              </label>
              <Select
              className="newProjectSelect"
              labelId="actId"
              id="actId"
              open={openAct}
              onClose={() => setOpenAct(false)}
              onOpen={() => setOpenAct(true)}
              value={actId}
              onChange={handleActIdChange}
              style={{ padding: "8px 10px" }}
            >
              {activities?.map((act) => (
                // Print the name depending on the language
                <MenuItem key={act.intID} value={act.intID}>
                  {act[`name${language?.toLocaleUpperCase()}`]}
                </MenuItem>
              ))}
            </Select>

            </Grid>

            <Grid item md={4} sm={6} className="form-group">
              <label id="orgId" className="control-label">
                Organization id
              </label>
              <Select
                className="newProjectSelect"
                labelId="orgId"
                id="orgId"
                open={openOrg}
                onClose={() => setOpenOrg(false)}
                onOpen={() => setOpenOrg(true)}
                value={orgId}
                onChange={handleOrgIdChange}
                style={{ padding: "5px 10px" }}
              >
                {orgs?.map((org) => (
                  
                  // Print the name depending on the language
                  <MenuItem key={org.intID} value={org.intID}>
                    {org[`name${language?.toLocaleUpperCase()}`]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item md={4} sm={6} className="form-group">
              <label id="applicantName" className="control-label">
                Applicant Name
              </label>

              <FormControl
                type="text"
                value={applicantName}
                onChange={(e) => setApplicantName(e.target.value)}
                name="applicantName"
                htmlFor="applicantName"
                placeholder="Enter Applicant Name"
                required
              />
            </Grid>
            <Grid item md={4} sm={6} className="form-group">
              <label id="targetAmount" className="control-label">
              target Amount
              </label>

              
              <FormControl
                type="number"
                value={targetAmount}
                onChange={(e) => setTargetAmount(e.target.value)}
                name="targetAmount"
                htmlFor="targetAmount"
                placeholder="Enter Target Amount"
                required
              />
            </Grid>
            <Grid item md={4} sm={12} className="form-group">
            
            <label id="shareLink">share link</label>
            <FormControl
              type="text"
              value={share}
              onChange={(e) => setShare(e.target.value)}
              name="shareLink"
              htmlFor="shareLink"
              placeholder="Enter Link"
              required
            />
       </Grid>
          </Grid>
            </Grid>
           
    
          <Grid item sm={12}>
            <div
              className="form-group"
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <ToggleButton
                className="mb-2 checkboxButton"
                id="toggle-check"
                type="checkbox"
                // variant="outline-primary"
                checked={isApproved}
                onChange={() => setIsApproved((preValue) => !preValue)}
              ></ToggleButton>
              <span className="" style={{ marginLeft: "5px" }}>
                {isApproved ? "Approved" : "Not Approved"}
              </span>

              
            </div>
          </Grid>
          <Grid item sm={12}>

        <div className="fieldDiv">
          <label>
            {isEditingMode ? "Change" : "Upload"} Person Image
            <img
              src={preview || imageURL}
              alt="default"
              style={{ width: "124px", height: "124px", display: "block" }}
            />
          </label>
          <div className="file-input">
            <input
              style={{ width: "48px", cursor: "pointer" }}
              type="file"
              accept="image/*"
              placeholder="Choose Person Image"
              onChange={storeImageHandler}
              className="file"
              id="file"
              ref={fileInputRef}
            />
            <button
              htmlFor="file"
              className="uploadBtn"
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
            >
              {selectedImage || isEditingMode ? "Change " : "Select "}
            </button>
            {/* <p className="file-name"></p> */}
            <button
              className="removeBtn"
              type="button"
              onClick={() => {
                setSelectedImage(null);
                setPreview(null);
              }}
            >
              Remove
            </button>
          </div>
          <div style={{ marginTop: "20px" }}>
            {selectedImage && (
              <React.Fragment>
            {!imageURL&&<p>Please Upload the image</p>}
              
              <button
              style={{marginTop:".4em"}}
                onClick={uploadImageHandler}
                className="uploadBtn"
                >
                  Upload Image
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
          </Grid>
        </Grid>
        <div className="submit">
          <Button
            disabled={!edit}
            className="create-edit-form-btn"
            type="submit"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
          setShow={setShow}
          show={show}
        ></ConfirmModal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.InMemory.isPending,
  inMemoryPerson: state.InMemory.inMemoryPerson,
  inMemoryPersonImage: state.InMemory.inMemoryPersonImage,
  activities: state.Activities.activities,
  orgs: state.ORGs.orgs,
});

const actions = (dispatch) => ({
  getInMemoryPerson: (personId) => dispatch(getInMemoryPerson(personId)),
  editInMemoryPerson: (personId, newInMemoryPersonData) =>
    dispatch(editInMemoryPerson(personId, newInMemoryPersonData)),
  uploadPersonPhoto: (image) => dispatch(uploadPersonPhoto(image)),
  createInMemoryPerson: (newInMemoryPersonData) =>
    dispatch(createInMemoryPerson(newInMemoryPersonData)),
});

export default connect(mapStateToProps, actions)(AddInMemoryOf);
