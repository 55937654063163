import { composeWithDevTools } from "@redux-devtools/extension";
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers,
    }),
    {},
    composeWithDevTools(applyMiddleware(thunk))
  );
}
