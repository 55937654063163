import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import "assets/styles/base.scss";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import "./NotificationDataTable.css";
import parse from 'html-react-parser';
const NotificationDataTable = ({
  notification,
  deleteNotification,
  key,
  headSelected,
  handleClick,
  isItemSelected,
  labelId,
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };
  const deleteNotificationHandler = () => {
    deleteNotification(notification.objectId);
  };

  // const deleteMemoryPersonHandler = () => {
  //   deleteInMemoryPerson(person.objectId);
  // };

  // useEffect(() => {
  //   setIsPersonApproved(person.Approved);
  // }, []);

  return (
    <React.Fragment>
      <TableRow
        hover
   
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={key}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
                onClick={(event) => handleClick(event, notification.objectId)}
                role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
        {headSelected.notificationEn && (
          <TableCell
            dangerouslySetInnerHTML={{ __html: notification.notificationText }}
          ></TableCell>
        )}
        {headSelected.notificationAr && (
          <TableCell
            dangerouslySetInnerHTML={{
              __html: notification.notificationTextInAR,
            }}
          ></TableCell>
        )}

        {headSelected.createdAt && (
          <TableCell>{dateFormatter(notification.createdAt)}</TableCell>
        )}

        {headSelected.Actions && (
          <TableCell>
            <div className="TableIcons">

            <Link to={`/editNotification/${notification.objectId}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>
            <RiDeleteBinLine
              cursor="pointer"
              onClick={openConfirmModalHandler}
              color="#ED1650"
              size={24}
            />
            </div>
          </TableCell>
        )}
      </TableRow>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteNotificationHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default NotificationDataTable;
