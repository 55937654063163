import networkService from "../api.js";

const defaultState = {
  mnos: [],
  error: null,
  isPending: null,
  loaded: false,
};
const LOAD_MNOS_LIST = "LOAD_MNOS_LIST";
const LOAD_MNOS_LIST_PENDING = "LOAD_MNOS_LIST_PENDING";
const LOAD_MNOS_LIST_ERROR = "LOAD_MNOS_LIST_ERROR";

export const loadMNOsList = () => (dispatch, getState) => {
  if (!getState().MNOs.loaded) {
    dispatch({ type: LOAD_MNOS_LIST_PENDING });
    networkService
      .send("mnos")
      .then((res) => {
        dispatch({
          type: LOAD_MNOS_LIST,
          MNOs: res.data.MNOs,
        });
      })
      .catch((error) => {
        dispatch({ type: LOAD_MNOS_LIST_ERROR, error });
      });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_MNOS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        mnos: action.MNOs,
      };
    case LOAD_MNOS_LIST_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: true,
      };
    case LOAD_MNOS_LIST_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        loaded: false,
      };
    default:
      return state;
  }
}
