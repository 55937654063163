// import { Pagination } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NewDataTable from "../../components/NewDataTable";
import SelectedVideosDataTable from "../../components/SelectedVideosDataTable/SelectedVideosDataTable";
import {
  deleteSelectedVideo,
  getAllSelectedVideos,
} from "../../reducers/SelectedVideos";
import { Pagination, PaginationItem } from "@material-ui/lab";
import toaster from "../../modules/toaster";

const rowsPerPage = 10;

const selecttableHead = ["URL", "CreatedAt", "objectId", "Actions"];

const SelectedVideos = ({
  isPending,
  loadSelectedVideos,
  allSelectedVideos,
  history,
  deleteSelectedVideo,
}) => {
  useEffect(() => {
    loadSelectedVideos();
  }, []);

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    URL: true,
    CreatedAt: false,
    objectId: false,
    Actions: true,
  });

  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);
  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handlePaginationChange = (event, value) => {
    setPage(value);
    // setSkip((value - 1) * 20);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const addNewVideo = () => {
    history.push("addNewVideo");
  };

  if (isPending) {
    return <LoadingSpinner />;
  }
  const handleDelete=()=>{
    if (selected.length === 0) {
      toaster.warning("please select some videos");
    } else {
      Promise.allSettled(
        selected.map(e=> {
          return deleteSelectedVideo(e)
        })
      ).then(responseArr => {
        responseArr.forEach(res=>{
          console.log(res);
      
        })
      })
      setSelected([])
    }

  
  

  
  }
  return (
    <div>
      <div className="header">
        <h4 className="title">SelectedVideos</h4>
      </div>
      <div className="card">
        {allSelectedVideos.length > 0 ? (
          <NewDataTable
            title={"Add New Video"}
            csvTitle={"SelectedVideos"}
            add={addNewVideo}
            data={allSelectedVideos}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            handleDelete={handleDelete}
            pagination={
             
              <Pagination 
              // size="small" 
              page={page}
              count={Math.ceil(allSelectedVideos.length/10)}
              // total={projects.length} 
              onChange={handlePaginationChange} />
            }
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
          >
            {allSelectedVideos
              ?.slice((page-1) * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.objectId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <SelectedVideosDataTable
                    key={row.objectId}
                    selectedVideo={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    deleteSelectedVideo={deleteSelectedVideo}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
          <p style={{ textAlign: "center" }}>No  Selected Videos Found</p>
          <button
            className="addButton"
            onClick={() => {
              addNewVideo
            }}
          >
            <BsPlus size={24} />
            Add A new Video
          </button>
        </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    isPending: state.SelectedVideos.isPending,
    allSelectedVideos: state.SelectedVideos.allSelectedVideos,
  }),
  (dispatch) => ({
    loadSelectedVideos: () => dispatch(getAllSelectedVideos()),
    deleteSelectedVideo: (id) => dispatch(deleteSelectedVideo(id)),
  })
)(SelectedVideos);

SelectedVideos.propTypes = {
  isPending: PropTypes.bool,
  loadSelectedVideos: PropTypes.func,
  allSelectedVideos: PropTypes.array,
  history: PropTypes.object,
  deleteSelectedVideo: PropTypes.func,
};
