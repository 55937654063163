import axios from "axios";
import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const EMBASSADORS_PENDING = "EMBASSADORS_PENDING";
const CREATE_EMBASSADOR = "CREATE_EMBASSADOR";
const GET_AllEMBASSADORS = "GET_AllEMBASSADORS";
const GET_SINGLEEMBASSADOR = "GET_SINGLEEMBASSADOR";
const POST_AMBASSADORIMAGE = "POST_AMBASSADORIMAGE";
// const DELETE_AMBASSADORIMAGEDATA = "DELETE_AMBASSADORIMAGEDATA";
const EDIT_EMBASSADOR = "EDIT_EMBASSADOR";
const DELETE_EMBASSADOR = "DELETE_EMBASSADOR";
const APPROVE_EMBASSADOR = "APPROVE_EMBASSADOR";
const EMBASSADORS_ERROR = "EMBASSADORS_ERROR";

const endpoint = "GoodAmbassadors";

const initialState = {
  allBrandEmbassadors: [],
  singleEmbassador: {},
  ambassadorImage: "",
  error: null,
  isPending: false,
  loaded: false,
};

// CREATE New Embassador
export const createNewEmbassador = (newEmbassadorData) => async (dispatch) => {
  dispatch({
    type: EMBASSADORS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      endpoint,
      "post",
      newEmbassadorData,
      getConfigHeaders()
    );
    dispatch({
      type: CREATE_EMBASSADOR,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMBASSADORS_ERROR,
      payload: error,
    });
  }
};

// GET All Embassadors
export const getAllBrandEmbassadors = () => async (dispatch) => {
  dispatch({
    type: EMBASSADORS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      endpoint,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_AllEMBASSADORS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: EMBASSADORS_ERROR,
      payload: error,
    });
  }
};

// GET Single Embassadors
export const getSingleBrandEmbassador = (id) => async (dispatch) => {
  dispatch({
    type: EMBASSADORS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${endpoint}/${id}`,
      "get",
      getConfigHeaders()
    );

    dispatch({
      type: GET_SINGLEEMBASSADOR,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EMBASSADORS_ERROR,
      payload: error,
    });
  }
};

// EDIT Embassador
export const editEmbassador = (id, embassadorNewData) => async (dispatch) => {
  dispatch({
    type: EMBASSADORS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${endpoint}/${id}`,
      "put",
      embassadorNewData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_EMBASSADOR,
      payload: { id, embassadorNewData },
    });
  } catch (error) {
    dispatch({
      type: EMBASSADORS_ERROR,
      payload: error,
    });
  }
};

// Delete Embassador
export const deleteEmbassador = (id) => async (dispatch) => {
  dispatch({
    type: EMBASSADORS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${endpoint}/${id}`,
      "delete",
      getConfigHeaders()
    );
    dispatch({
      type: DELETE_EMBASSADOR,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: EMBASSADORS_ERROR,
      payload: error,
    });
  }
};

// export const wipeAmbassadorImageData = () => (dispatch) => {
//   dispatch({
//     type: DELETE_AMBASSADORIMAGEDATA,
//     payload: "",
//   });
// };

// Image Upload
export const uploadAmbassadorPhoto = (image) => async (dispatch) => {
  dispatch({
    type: EMBASSADORS_PENDING,
    payload: true,
  });
  try {
    const { data } = await axios.post(
      "https://api.appgain.io/5e403f53970b2b000b92de74/upload",
      image,
      {
        headers: {
          appApiKey:
            "ddfa1b658abf0b2f8a6e87d6efcfec7c3d2b4980850b21237febae38664130a6",
        },
      }
    );
    dispatch({
      type: POST_AMBASSADORIMAGE,
      payload: `https://${data}`,
    });
  } catch (error) {
    dispatch({
      type: EMBASSADORS_ERROR,
      payload: error,
    });
  }
};

export const approveEmbassador = (id, body) => async (dispatch) => {
  dispatch({
    type: EMBASSADORS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${endpoint}/${id}`,
      "put",
      body,
      getConfigHeaders()
    );
    dispatch({
      type: APPROVE_EMBASSADOR,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: EMBASSADORS_ERROR,
      payload: error,
    });
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EMBASSADORS_PENDING:
      return { ...state, isPending: true, loaded: false };
    case CREATE_EMBASSADOR:
      return {
        ...state,
        allBrandEmbassadors: [...state.allBrandEmbassadors, action.payload],
        isPending: false,
        loaded: true,
      };
    case POST_AMBASSADORIMAGE:
      return {
        ...state,
        ambassadorImage: action.payload,
        isPending: false,
        loaded: true,
      };
    // case DELETE_AMBASSADORIMAGEDATA:
    //   return { ...state, ambassadorImage: action.payload };
    case GET_AllEMBASSADORS:
      return {
        ...state,
        allBrandEmbassadors: action.payload,
        isPending: false,
        loaded: true,
      };
    case GET_SINGLEEMBASSADOR:
      return {
        ...state,
        singleEmbassador: action.payload,
        isPending: false,
        loaded: true,
      };
    case EDIT_EMBASSADOR:
      return {
        ...state,
        allBrandEmbassadors: state.allBrandEmbassadors?.map((embassador) =>
          embassador.objectId === action.payload.id
            ? action.payload.embassadorNewData
            : embassador
        ),
        isPending: false,
        loaded: true,
      };
    case APPROVE_EMBASSADOR:
      return {
        ...state,
        allBrandEmbassadors: state.allBrandEmbassadors?.map((embassador) =>
          embassador.objectId === action.payload
            ? { ...embassador, Approved: !embassador.Approved }
            : embassador
        ),
        isPending: false,
        loaded: true,
      };
    case DELETE_EMBASSADOR:
      return {
        ...state,
        allBrandEmbassadors: state.allBrandEmbassadors.filter(
          (embassador) => embassador.objectId !== action.payload
        ),
        isPending: false,
        loaded: true,
      };

    default:
      return { ...state };
  }
};

export default reducer;
