import { Pagination } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NewDataTable from "../../components/NewDataTable";
import TeamsDataTable from "../../components/TeamsDataTable";
import toaster from "../../modules/toaster";
import { deleteTeam, getTeams } from "../../reducers/Teams";
import "./Teams.css";

const rowsPerPage = 10;

const selecttableHead = ["Photo", "Name", "Title", "Actions"];

const Teams = ({ teams, getTeams, deleteTeam, isPending, history }) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    Photo: true,
    Name: true,
    Title: true,
    Actions: true,
  });

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    setData(teams);
  }, [teams]);

  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAddTeam = () => {
    history.push("addTeam");
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleDelete = () => {
    if (selected.length === 0) {
      toaster.warning("please select items");
    } else {
      Promise.allSettled(
        selected.map((e) => {
          return deleteTeam(e);
        })
      ).then((responseArr) => {
        responseArr.forEach((res) => {
          console.log(res);
        });
      });
      setSelected([]);
    }
  };
  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <div className="header">
        <h4 className="title">Teams</h4>
      </div>

      <div className="card">
        {data.length > 0 ? (
          <NewDataTable
            title={"Add a Team"}
            csvTitle={"Teams"}
            add={handleAddTeam}
            data={data}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
              <Pagination
                size="small"
                total={data.length}
                onChange={handlePaginationChange}
              />
            }
          >
            {data
              ?.slice(
                (page - 1) * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.objectId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TeamsDataTable
                    key={row.objectId}
                    team={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    isPending={isPending}
                    deleteTeam={deleteTeam}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
            <p style={{ textAlign: "center" }}>No teams Found</p>
            <button
              className="addButton"
              onClick={() => {
                handleAddTeam();
              }}
            >
              <BsPlus size={24} />
              Add A new team
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    teams: state.Teams.teams,
    isPending: state.Teams.isPending,
    team: state.Teams.team,
  }),
  (dispatch) => ({
    getTeams: () => dispatch(getTeams()),
    deleteTeam: (id) => dispatch(deleteTeam(id)),
  })
)(Teams);

Teams.propTypes = {
  teams: PropTypes.array.isRequired,
  getTeams: PropTypes.func.isRequired,
  deleteTeam: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};
