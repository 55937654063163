import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { setSelectedOrg } from "../reducers/ORGs";

const SelectOrg = ({ selectOrg, orgs, selectedOrg }) => {
  return (
    <div className="topProjectSelect">
      {!selectedOrg&&<label id="demo-simple-select-label" className="label">Select Organization</label>}

      <Select
        labelId="demo-simple-select-label"
        id="select-org"
        value={selectedOrg}
        onChange={(e) => {
          selectOrg(e.target.value);
        }}
        style={{ width: "100%" }}
      >
      
        {orgs?.map((el) => (
          <MenuItem key={el.id} value={el.id}>
            {el.nameEN}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const mapStateToProp = (state) => ({
  orgs: state.ORGs.orgs,
  selectedOrg: state.ORGs.selectedOrg,
});

const mapDispatchToProp = (dispatch) => ({
  selectOrg: (org) => dispatch(setSelectedOrg(org)),
});

export default connect(mapStateToProp, mapDispatchToProp)(SelectOrg);

SelectOrg.propTypes = {
  selectOrg: PropTypes.func,
  orgs: PropTypes.array,
  selectedOrg: PropTypes.string,
};
