import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const SELECTED_VIDEOS_PENDING = "SELECTED_VIDEOS_PENDING";
const CREATE_SELECTED_VIDEO = "CREATE_SELECTED_VIDEO";
const GET_SELECTED_VIDEOS = "GET_SELECTED_VIDEOS";
const GET_SINGLE_VIDEO = "GET_SINGLE_VIDEO";
const EDIT_SELECTED_VIDEO = "EDIT_SELECTED_VIDEO";
const DELETE_SELECTED_VIDEO = "DELETE_SELECTED_VIDEO";
const SELECTED_VIDEOS_ERROR = "SELECTED_VIDEOS_ERROR";

const endpointSelected = "selectedVideos";

const initialState = {
  allSelectedVideos: [],
  singleVideo: {},
  error: null,
  isPending: false,
  loaded: false,
};

// CREATE New Selected Video
export const createNewSelectedVideo =
  (newSelectedVideoData) => async (dispatch) => {
    dispatch({
      type: SELECTED_VIDEOS_PENDING,
      payload: true,
    });
    try {
      const data = await appBackendAppgainUrl(
        endpointSelected,
        "post",
        newSelectedVideoData,
        getConfigHeaders()
      );
      dispatch({
        type: CREATE_SELECTED_VIDEO,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SELECTED_VIDEOS_ERROR,
        payload: error,
      });
    }
  };

// GET All Selected Videos
export const getAllSelectedVideos = () => async (dispatch) => {
  dispatch({
    type: SELECTED_VIDEOS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      endpointSelected,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_SELECTED_VIDEOS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: SELECTED_VIDEOS_ERROR,
      payload: error,
    });
  }
};

// GET Single Video
export const getSingleVideo = (id) => async (dispatch) => {
  dispatch({
    type: SELECTED_VIDEOS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${endpointSelected}/${id}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_SINGLE_VIDEO,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SELECTED_VIDEOS_ERROR,
      payload: error,
    });
  }
};

// EDIT Selected Video
export const editSelectedVideo =
  (id, selectedVideoNewData) => async (dispatch) => {
    dispatch({
      type: SELECTED_VIDEOS_PENDING,
      payload: true,
    });
    try {
      await appBackendAppgainUrl(
        `${endpointSelected}/${id}`,
        "put",
        selectedVideoNewData,
        getConfigHeaders()
      );
      dispatch({
        type: EDIT_SELECTED_VIDEO,
        payload: { id, selectedVideoNewData },
      });
    } catch (error) {
      dispatch({
        type: SELECTED_VIDEOS_ERROR,
        payload: error,
      });
    }
  };

// Delete SelectedVideo
export const deleteSelectedVideo = (id) => async (dispatch) => {
  dispatch({
    type: SELECTED_VIDEOS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${endpointSelected}/${id}`,
      "delete",
      getConfigHeaders()
    );
    dispatch({
      type: DELETE_SELECTED_VIDEO,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: SELECTED_VIDEOS_ERROR,
      payload: error,
    });
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_VIDEOS_PENDING:
      return { ...state, isPending: true, loaded: false };
    case CREATE_SELECTED_VIDEO:
      return {
        ...state,
        allSelectedVideos: [...state.allSelectedVideos, action.payload],
        isPending: false,
        loaded: true,
      };

    case GET_SELECTED_VIDEOS:
      return {
        ...state,
        allSelectedVideos: action.payload,
        isPending: false,
        loaded: true,
      };

    case EDIT_SELECTED_VIDEO:
      return {
        ...state,
        allSelectedVideos: state.allSelectedVideos?.map((video) =>
          video.objectId === action.payload.id
            ? action.payload.selectedVideoNewData
            : video
        ),
        isPending: false,
        loaded: true,
      };

    case DELETE_SELECTED_VIDEO:
      return {
        ...state,
        allSelectedVideos: state.allSelectedVideos.filter(
          (video) => video.objectId !== action.payload
        ),
        isPending: false,
        loaded: true,
      };

    case GET_SINGLE_VIDEO:
      return {
        ...state,
        singleVideo: action.payload,
        isPending: false,
        loaded: true,
      };
    default:
      return { ...state };
  }
};

export default reducer;
