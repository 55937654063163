import defaultImg from "assets/images/defaultImg.svg";
import Joi from "joi";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import toaster from "../../modules/toaster";
import {
  createOrg,
  editOrg,
  getSingleOrg,
  loadORGsList,
  updatePaymentData,
  uploadOrgImage,
} from "../../reducers/ORGs";

const schema = Joi.object({
  address: Joi.string().max(150).required(),
  email: Joi.string().max(45).required(),
  fax: Joi.string().max(15).required(),
  fb: Joi.string().max(100).required(),
  nameEN: Joi.string().max(200).required(),
  nameAR: Joi.string().max(200).required(),
  infoEN: Joi.string().max(2000).required(),
  infoAR: Joi.string().max(2000).required(),
  tel: Joi.string().max(15).required(),
  tw: Joi.string().max(100).required(),
  web: Joi.string().max(45).required(),
});

const AddOrganization = (props) => {
  const {
    getSelectedOrg,
    selectedOrg,
    history,
    editOrganization,
    createOrganization,
    isPending,
    uploadImage,
    orgImage,
    loadAllORGs,
    updatePaymentData,
  } = props;
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [intId, setIntId] = useState(Date.now() % 100000);
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [fax, setFax] = useState("");
  const [fb, setFb] = useState("");
  const [infoAR, setInfoAR] = useState("");
  const [infoEN, setInfoEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [tel, setTel] = useState("");
  const [tw, setTw] = useState("");
  const [web, setWeb] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [show, setShow] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [imageUpload, setImageUpload] = useState(false);

  const [paymentData, setPaymentData] = useState({
    paytabs_token: "",
    paytabs_profile_id: "",
  });

  localStorage.setItem("intId", intId);
  const intIdFromStorage = localStorage.getItem("intId");
  function checkImage(imageSrc, good, bad) {
    var img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }
  const fileInputRef = useRef();

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);
  const orgId =
    window.location.hash.includes("editOrganization") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];

  useEffect(() => {
    if (orgId) {
      getSelectedOrg(orgId);
      setIsEditingMode(true);

      // set fields
      setWeb(selectedOrg.web);
      setTw(selectedOrg.tw);
      setTel(selectedOrg.tel);
      setNameEN(selectedOrg.nameEN);
      setNameAR(selectedOrg.nameAR);
      setInfoEN(selectedOrg.infoEN);
      setInfoAR(selectedOrg.infoAR);
      setFb(selectedOrg.fb);
      setFax(selectedOrg.fax);
      setEmail(selectedOrg.email);
      setAddress(selectedOrg.adress);
      setIntId(selectedOrg.int_id);

      setPaymentData({
        paytabs_token: selectedOrg?.paytabs_token || "",
        paytabs_profile_id: selectedOrg?.paytabs_profile_id || "",
      });

      checkImage(
        `https://s3.amazonaws.com/content.quantatel.com/ikhair/images/organziations/${selectedOrg.int_id}-214.png`,
        function () {
          setPreview(
            `https://s3.amazonaws.com/content.quantatel.com/ikhair/images/organziations/${selectedOrg.int_id}-214.png`
          );
        },
        function () {
          setPreview();
        }
      );
    } else {
      setIsEditingMode(false);
    }
  }, [
    orgId,
    selectedOrg.web,
    selectedOrg.tw,
    selectedOrg.tel,
    selectedOrg.nameEN,
    selectedOrg.nameAR,
    selectedOrg.infoAR,
    selectedOrg.infoEN,
    selectedOrg.fb,
    selectedOrg.fax,
    selectedOrg.email,
    selectedOrg.adress,
    selectedOrg.int_id,
  ]);

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage.size > 512000) {
        setShow(true);
        setSelectedImage("");
      }
    }
  }, [selectedImage]);

  const handleOrganizationSubmit = (e) => {
    e.preventDefault();

    const organizationData = {
      int_id: intIdFromStorage,
      address,
      ReportingEmail: "",
      isPremium: 0,
      takamul_ngo_id: "0",
      email,
      fax,
      fb,
      infoAR,
      infoEN,
      nameAR,
      nameEN,
      tel,
      tw,
      web,
      // orgImage
    };

    console.log("organizationData", organizationData);

    const { error } = schema.validate({
      address: organizationData.address,
      email: organizationData.email,
      fax: organizationData.fax,
      fb: organizationData.fb,
      nameEN: organizationData.nameEN,
      nameAR: organizationData.nameAR,
      infoEN: organizationData.infoEN,
      infoAR: organizationData.infoAR,
      tel: organizationData.tel,
      tw: organizationData.tw,
      web: organizationData.web,
    });

    if (error) {
      return toaster.error(error.details[0].message);
    }

    if (isEditingMode) {
      editOrganization({
        organization_data_to_update: organizationData,
        id: orgId,
      }).then(history.push("/organizations"));
    } else {
      createOrganization(organizationData);
      history.goBack();
      // .then(toaster.success("organization created successfully"))
      loadAllORGs();
      // .then(history.push("/organizations"));
    }
  };

  const storeImageHandler = (e) => {
    e.preventDefault();

    setSelectedImage(e.target.files[0]);
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        setImageDimensions({ width, height });
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };

  // upload image
  const uploadLogoHandler = () => {
    if (selectedOrg.int_id) {
      uploadImage(selectedImage, selectedOrg.int_id);
      setImageUpload(true);
    } else {
      uploadImage(selectedImage, intIdFromStorage);
      setImageUpload(true);
    }
  };

  const handleUpdatePaymentData = () => {
    updatePaymentData({
      organization_payment_data: {
        ...paymentData,
      },
      organization_id: selectedOrg.id,
    });
  };

  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="header">
        <h4 className="title">
          {isEditingMode
            ? `Edit Organization: ${selectedOrg.nameEN}`
            : "Add New Organization"}
        </h4>
      </div>
      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={handleOrganizationSubmit}
      >
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="intId" className="control-label">
              int_id
            </label>
            <FormControl
              type="text"
              value={intId}
              readOnly
              name="intId"
              htmlFor="intId"
              placeholder="Enter Int_id"
              // onChange={(e) => setIntId(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="address" className="control-label">
              address
            </label>
            <FormControl
              type="text"
              value={address}
              name="address"
              htmlFor="address"
              placeholder="Enter address"
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="email" className="control-label">
              email
            </label>
            <FormControl
              type="text"
              value={email}
              name="email"
              htmlFor="email"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="fax" className="control-label">
              fax
            </label>
            <FormControl
              type="text"
              value={fax}
              name="fax"
              htmlFor="fax"
              placeholder="Enter fax"
              onChange={(e) => setFax(e.target.value)}
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="fb" className="control-label">
              fb
            </label>
            <FormControl
              type="text"
              value={fb}
              name="fb"
              htmlFor="fb"
              placeholder="Enter fb"
              onChange={(e) => setFb(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="intId" className="control-label">
              infoAR
            </label>
            <FormControl
              type="text"
              value={infoAR}
              name="infoAR"
              htmlFor="infoAR"
              placeholder="Enter infoAR"
              onChange={(e) => setInfoAR(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="infoEN" className="control-label">
              infoEN
            </label>
            <FormControl
              type="text"
              value={infoEN}
              name="infoEN"
              htmlFor="infoEN"
              placeholder="Enter infoEN"
              onChange={(e) => setInfoEN(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="nameAR" className="control-label">
              nameAR
            </label>
            <FormControl
              type="text"
              value={nameAR}
              name="nameAR"
              htmlFor="nameAR"
              placeholder="Enter nameAR"
              onChange={(e) => setNameAR(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="nameEN" className="control-label">
              nameEN
            </label>
            <FormControl
              type="text"
              value={nameEN}
              name="nameEN"
              htmlFor="nameEN"
              placeholder="Enter nameEN"
              onChange={(e) => setNameEN(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="tel" className="control-label">
              tel
            </label>
            <FormControl
              type="text"
              value={tel}
              name="tel"
              htmlFor="tel"
              placeholder="Enter tel"
              onChange={(e) => setTel(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="tw" className="control-label">
              tw
            </label>
            <FormControl
              type="text"
              value={tw}
              name="tw"
              htmlFor="tw"
              placeholder="Enter tw"
              onChange={(e) => setTw(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="web" className="control-label">
              web
            </label>
            <FormControl
              type="text"
              value={web}
              name="web"
              htmlFor="web"
              placeholder="Enter web"
              onChange={(e) => setWeb(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="fieldDiv form-group">
          <label className="control-label">
            {isEditingMode ? "Change" : "Upload"} Organization Image
            <img
              src={preview || defaultImg}
              alt="default"
              style={{ width: "124px", height: "124px", display: "block" }}
            />{" "}
          </label>
          <div className="file-input">
            <input
              style={{ width: "48px" }}
              type="file"
              accept="image/*"
              placeholder="Choose Embassador Image"
              onChange={storeImageHandler}
              className="file"
              id="file"
              ref={fileInputRef}
            />
            <p>Please ensure that the image dimensions are 512x512</p>
            {imageDimensions.width != 0 && (
              <div>
                <p>Image Width: {imageDimensions.width}</p>
                <p>Image Height: {imageDimensions.height}</p>
              </div>
            )}
            <p>* Please ensure the the image type is `png`</p>
            {image?.type && (
              <ul style={{ listStyleType: "none" }}>
                <li>{image.type}</li>
              </ul>
            )}
            <button
              htmlFor="file"
              className="uploadBtn"
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
            >
              {selectedImage || isEditingMode ? "Change " : "Select "}
            </button>

            <button
              className="removeBtn"
              type="button"
              onClick={() => {
                setSelectedImage(null);
                setPreview(null);
                setImageDimensions({
                  width: 0,
                  height: 0,
                });
              }}
            >
              Remove
            </button>
          </div>
          {image?.type && (
            <div>
              {image?.type === "image/png" ? (
                <div style={{ marginTop: "20px" }}>
                  {selectedImage && (
                    <React.Fragment>
                      {!orgImage && !imageUpload && (
                        <>
                          <p>Please Upload the image</p>
                          <button
                            style={{ marginTop: ".4em" }}
                            onClick={uploadLogoHandler}
                            className="uploadBtn"
                          >
                            Upload photo
                          </button>
                        </>
                      )}
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <p style={{ color: "red", marginTop: "10px" }}>
                  invalid image type
                </p>
              )}
            </div>
          )}

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <div>
              <label>Paytabs Token</label>
              <input
                type="text"
                value={paymentData.paytabs_token}
                onChange={(e) =>
                  setPaymentData({
                    ...paymentData,
                    paytabs_token: e.target.value,
                  })
                }
              />
            </div>

            <div>
              <label>Paytabs Profile ID</label>
              <input
                type="text"
                value={paymentData.paytabs_profile_id}
                onChange={(e) =>
                  setPaymentData({
                    ...paymentData,
                    paytabs_profile_id: e.target.value,
                  })
                }
              />
            </div>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                className="create-edit-form-btn"
                type="button"
                onClick={() => {
                  setPaymentData({
                    paytabs_token: "",
                    paytabs_profile_id: "",
                  });
                }}
              >
                Clear Payment Data
              </Button>

              <Button
                className="create-edit-form-btn"
                type="button"
                onClick={handleUpdatePaymentData}
              >
                Save Payment Data
              </Button>
            </div>
          </div>
        </div>

        <div className="submit">
          <Button
            // disabled={!edit}
            className="create-edit-form-btn"
            type="submit"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
      </form>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
          setShow={setShow}
          show={show}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.singleOrg,
    isPending: state.ORGs.isPending,
    orgImage: state.ORGs.orgImage,
  }),
  (dispatch) => ({
    getSelectedOrg: (id) => dispatch(getSingleOrg(id)),
    editOrganization: (data) => dispatch(editOrg(data)),
    createOrganization: (data) => dispatch(createOrg(data)),
    uploadImage: (image, orgId) => dispatch(uploadOrgImage(image, orgId)),
    loadAllORGs: () => dispatch(loadORGsList()),
    updatePaymentData: (data) => dispatch(updatePaymentData(data)),
  })
)(AddOrganization);

AddOrganization.propTypes = {
  getSelectedOrg: PropTypes.func,
  selectedOrg: PropTypes.object,
  history: PropTypes.object,
  editOrganization: PropTypes.func,
  createOrganization: PropTypes.func,
  isPending: PropTypes.bool,
  uploadImage: PropTypes.func,
};
