import defaultImg from "assets/images/defaultImg.svg";
import Joi from "joi";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import toaster from "../../modules/toaster";
import {
  createActivity,
  editActivity,
  getSingleActivity,
  uploadActivityImage,
} from "../../reducers/Activities";

const schema = Joi.object({
  nameEN: Joi.string().max(45).required(),
  nameAR: Joi.string().max(45).required(),
  infoAR: Joi.string().max(1000).required(),
  infoEN: Joi.string().max(1000).required(),
  order: Joi.number().min(0).required(),
});

const AddActivity = (props) => {
  const {
    getSelectedActivity,
    selectedActivity,
    history,
    editActivity,
    createActivity,
    uploadImage,
    actImage,
    isPending,
    activities,
  } = props;
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [order, setOrder] = useState();
  const [infoAR, setInfoAR] = useState("");
  const [infoEN, setInfoEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [intId, setIntId] = useState(Date.now() % 100000);
  const [selectedImage, setSelectedImage] = useState();
  const [show, setShow] = useState(false);
  const fileInputRef = useRef();

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [imageUpload, setImageUpload] = useState(false);
  localStorage.setItem("intId", intId);
  const intIdFromStorage = localStorage.getItem("intId");
  function checkImage(imageSrc, good, bad) {
    var img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);
  const activityId =
    window.location.hash.includes("editActivity") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];

  useEffect(() => {
    if (activityId) {
      getSelectedActivity(activityId);
      setIsEditingMode(true);

      // set fields

      setNameEN(selectedActivity.nameEN);
      setNameAR(selectedActivity.nameAR);
      setInfoEN(selectedActivity.infoEN);
      setInfoAR(selectedActivity.infoAR);
      setOrder(selectedActivity.order);
      setIntId(selectedActivity.int_id);
      setPreview(actImage);

      checkImage(
        `https://s3.amazonaws.com/content.quantatel.com/ikhair/images/activites/${selectedActivity.int_id}.png`,
        function () {
          setPreview(
            `https://s3.amazonaws.com/content.quantatel.com/ikhair/images/activites/${selectedActivity.int_id}.png`
          );
        },
        function () {
          setPreview();
        }
      );
    } else {
      setIsEditingMode(false);
    }
  }, [
    activityId,
    selectedActivity.nameEN,
    selectedActivity.nameAR,
    selectedActivity.infoAR,
    selectedActivity.infoEN,
    selectedActivity.order,
    selectedActivity.int_id,
  ]);

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage.size > 512000) {
        setShow(true);
        setSelectedImage("");
      }
    }
  }, [selectedImage]);

  const handleActivitySubmit = (e) => {
    e.preventDefault();

    const { error } = schema.validate({
      nameEN,
      nameAR,
      infoEN,
      infoAR,
      order,
    });

    if (error) {
      return toaster.error(error.details[0].message);
    }

    const activityData = {
      int_id: intIdFromStorage,
      order,
      infoAR,
      infoEN,
      nameAR,
      nameEN,
      avalibleforCauses: 1,
      activityImage: actImage,
    };

    if (isEditingMode) {
      editActivity({
        activity_data_to_update: activityData,
        id: activityId,
      });
    } else {
      createActivity(activityData);
    }

    history.push("/activities");
  };

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedImage(e.target.files[0]);
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        setImageDimensions({ width, height });
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  const uploadLogoHandler = () => {
    if (selectedActivity.int_id) {
      uploadImage(selectedImage, selectedActivity.int_id);
      setImageUpload(true);
    } else {
      uploadImage(selectedImage, intIdFromStorage);
      setImageUpload(true);
    }
  };
  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <div className="header">
        <h4 className="title">
          {isEditingMode
            ? `Edit Activity: ${selectedActivity.nameEN}`
            : "Add New Activity"}
        </h4>
      </div>

      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={handleActivitySubmit}
      >
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="intId" className="control-label">
              int_id
            </label>
            <FormControl
              type="text"
              readOnly
              value={intIdFromStorage}
              name="intId"
              htmlFor="intId"
              placeholder="Enter Int_id"
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="order" className="control-label">
              order
            </label>
            <FormControl
              type="number"
              value={order}
              name="order"
              htmlFor="order"
              placeholder="Enter order"
              onChange={(e) => setOrder(parseInt(e.target.value))}
              required
            />
          </div>
        </div>
        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="intId" className="control-label">
              infoAR
            </label>
            <FormControl
              type="text"
              value={infoAR}
              name="infoAR"
              htmlFor="infoAR"
              placeholder="Enter infoAR"
              onChange={(e) => setInfoAR(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="infoEN" className="control-label">
              infoEN
            </label>
            <FormControl
              type="text"
              value={infoEN}
              name="infoEN"
              htmlFor="infoEN"
              placeholder="Enter infoEN"
              onChange={(e) => setInfoEN(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="formGroup">
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="nameAR" className="control-label">
              nameAR
            </label>
            <FormControl
              type="text"
              value={nameAR}
              name="nameAR"
              htmlFor="nameAR"
              placeholder="Enter nameAR"
              onChange={(e) => setNameAR(e.target.value)}
              required
            />
          </div>
          <div className="fieldDiv form-group" style={{ width: "50%" }}>
            <label id="nameEN" className="control-label">
              nameEN
            </label>
            <FormControl
              type="text"
              value={nameEN}
              name="nameEN"
              htmlFor="nameEN"
              placeholder="Enter nameEN"
              onChange={(e) => {
                setNameEN(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="fieldDiv form-group">
          <label className="control-label">
            {isEditingMode ? "Change" : "Upload"} Activity Image
            <img
              src={preview || defaultImg}
              alt="default"
              style={{ width: "124px", height: "124px", display: "block" }}
            />{" "}
          </label>
          <div className="file-input">
            <input
              style={{ width: "48px" }}
              type="file"
              accept="image/*"
              placeholder="Choose Activity Image"
              onChange={storeImageHandler}
              className="file"
              id="file"
              ref={fileInputRef}
            />
            <p>Please ensure that the image dimensions are 512x512</p>
            {imageDimensions.width != 0 && (
              <ul style={{ listStyleType: "none" }}>
                <li>Image Width: {imageDimensions.width}</li>
                <li>Image Height: {imageDimensions.height}</li>
              </ul>
            )}
            <p>* Please ensure the the image type is `png`</p>
            {image?.type && (
              <ul style={{ listStyleType: "none" }}>
                <li>{image.type}</li>
              </ul>
            )}
            <button
              htmlFor="file"
              className="uploadBtn"
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
            >
              {selectedImage || isEditingMode ? "Change " : "Select "}
            </button>
            {/* <p className="file-name"></p> */}
            <button
              className="removeBtn"
              type="button"
              onClick={() => {
                setSelectedImage(null);
                setPreview(null);
                setImageDimensions({
                  width: 0,
                  height: 0,
                });
                setImage(null);
              }}
            >
              Remove
            </button>
          </div>
          {image?.type && (
            <div>
              {image?.type === "image/png" ? (
                <div style={{ marginTop: "20px" }}>
                  {selectedImage && !imageUpload && (
                    <React.Fragment>
                      {!actImage && <p>Please Upload this image</p>}

                      <button
                        type="button"
                        style={{ marginTop: ".4em" }}
                        onClick={uploadLogoHandler}
                        className="uploadBtn"
                      >
                        Upload photo
                      </button>
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <p style={{ color: "red", marginTop: "10px" }}>
                  invalid image type
                </p>
              )}
            </div>
          )}
        </div>
        <div className="submit">
          <Button
            // disabled={!edit}
            className="create-edit-form-btn"
            type="submit"
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
      </form>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
          setShow={setShow}
          show={show}
        ></ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    selectedActivity: state.Activities.singleActivity,
    actImage: state.Activities.activityImage,
    isPending: state.Activities.isPending,
    activities: state.Activities,
  }),
  (dispatch) => ({
    getSelectedActivity: (id) => dispatch(getSingleActivity(id)),
    editActivity: (data) => dispatch(editActivity(data)),
    createActivity: (data) => dispatch(createActivity(data)),
    uploadImage: (image, orgId) => dispatch(uploadActivityImage(image, orgId)),
  })
)(AddActivity);
