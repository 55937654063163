import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const RAMADAN_NOTES_PENDING = "RAMADAN_NOTES_PENDING";
const CREATE_RAMADAN_NOTES = "CREATE_RAMADAN_NOTES";
const GET_RAMADAN_NOTES = "GET_RAMADAN_NOTES";
const GET_SINGLE_RAMADAN_NOTES = "GET_SINGLE_RAMADAN_NOTES";
const EDIT_RAMADAN_NOTES = "EDIT_RAMADAN_NOTES";
const DELETE_RAMADAN_NOTES = "DELETE_RAMADAN_NOTES";
const RAMADAN_NOTES_ERROR = "RAMADAN_NOTES_ERROR";

const endpointRamadan = "RamadanNotes";

const initialState = {
  allRamadanNotes: [],
  singleRamadanNote: {},
  error: null,
  isPending: false,
  loaded: false,
};

// CREATE New Ramadan Note
export const createNewRamadanNotes = (newRamadanNote) => async (dispatch) => {
  dispatch({
    type: RAMADAN_NOTES_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      endpointRamadan,
      "post",
      newRamadanNote,
      getConfigHeaders()
    );
    dispatch({
      type: CREATE_RAMADAN_NOTES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RAMADAN_NOTES_ERROR,
      payload: error,
    });
  }
};

// GET All Ramadan Notes
export const getAllRamadanNotes = () => async (dispatch) => {
  dispatch({
    type: RAMADAN_NOTES_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      endpointRamadan,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_RAMADAN_NOTES,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: RAMADAN_NOTES_ERROR,
      payload: error,
    });
  }
};

// GET Single Ramadan Note
export const getSingleRamadanNote = (id) => async (dispatch) => {
  dispatch({
    type: RAMADAN_NOTES_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${endpointRamadan}/${id}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_SINGLE_RAMADAN_NOTES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RAMADAN_NOTES_ERROR,
      payload: error,
    });
  }
};

// EDIT Ramadan Note
export const editRamadanNote = (id, ramadanNote) => async (dispatch) => {
  dispatch({
    type: ramadanNote,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${endpointRamadan}/${id}`,
      "put",
      ramadanNote,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_RAMADAN_NOTES,
      payload: { id, ramadanNote },
    });
  } catch (error) {
    dispatch({
      type: RAMADAN_NOTES_ERROR,
      payload: error,
    });
  }
};

// Delete Ramadan Note
export const deleteRamadanNote = (id) => async (dispatch) => {
  dispatch({
    type: RAMADAN_NOTES_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${endpointRamadan}/${id}`,
      "delete",
      getConfigHeaders()
    );
    dispatch({
      type: DELETE_RAMADAN_NOTES,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: RAMADAN_NOTES_ERROR,
      payload: error,
    });
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RAMADAN_NOTES_ERROR:
      return { ...state, isPending: true, loaded: false };
    case CREATE_RAMADAN_NOTES:
      return {
        ...state,
        allRamadanNotes: [...state.allRamadanNotes, action.payload],
        isPending: false,
        loaded: true,
      };

    case GET_RAMADAN_NOTES:
      return {
        ...state,
        allRamadanNotes: action.payload,
        isPending: false,
        loaded: true,
      };

    case EDIT_RAMADAN_NOTES:
      return {
        ...state,
        allRamadanNotes: state.allRamadanNotes?.map((note) =>
          note.objectId === action.payload.id
            ? action.payload.ramadanNote
            : note
        ),
        isPending: false,
        loaded: true,
      };

    case DELETE_RAMADAN_NOTES:
      return {
        ...state,
        allRamadanNotes: state.allRamadanNotes.filter(
          (note) => note.objectId !== action.payload
        ),
        isPending: false,
        loaded: true,
      };

    case GET_SINGLE_RAMADAN_NOTES:
      return {
        ...state,
        singleRamadanNote: action.payload,
        isPending: false,
        loaded: true,
      };
    default:
      return { ...state };
  }
};

export default reducer;
