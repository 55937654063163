import defaultImg from "assets/images/defaultImg.svg";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, FormGroup, ToggleButton } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  createNewEmbassador,
  editEmbassador,
  getSingleBrandEmbassador,
  uploadAmbassadorPhoto,
} from "../../reducers/BrandEmbassadors";
import "./AddBrandEmbassador.css";

const AddBrandEmbassador = ({
  singleEmbassador,
  isPending,
  getSingleBrandEmbassador,
  uploadAmbassadorPhoto,
  ambassadorImage,
  editEmbassador,
  createNewEmbassador,
  history,
}) => {
  const embassadorId =
    window.location.hash.includes("editEmbassador") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [show, setShow] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [facebookLink, setFacebookLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const fileInputRef = useRef();

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const storeImageHandler = (e) => {
    e.preventDefault();
    setSelectedImage(e.target.files[0]);
    const file = e.target.files[0];
    setImage(file);
  };
  //
  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);
  // Upload Image
  const uploadImageHandler = () => {
    const fd = new FormData();
    fd.append("photo", selectedImage);
    uploadAmbassadorPhoto(fd);
  };

  const submitAmbassadorDataHandler = (e) => {
    e.preventDefault();
    if (description.getCurrentContent().getPlainText().trim().length <= 0) {
      setIsDescriptionRequired(true);
      return;
    }
    const ambassadorData = {
      name,
      description: stateToHTML(description.getCurrentContent()),
      FacebookLink: facebookLink,
      YoutubeLink: youtubeLink,
      TwitterLink: twitterLink,
      InstagramLink: instagramLink,
      Approved: isApproved,
      Photo: imageURL,
    };

    if (isEditingMode) {
      editEmbassador(embassadorId, ambassadorData);
    } else {
      createNewEmbassador(ambassadorData);
    }
    // wipeAmbassadorImageData();
    history.push("/brandEmbassadors");
  };

  useEffect(() => {
    if (embassadorId) {
      getSingleBrandEmbassador(embassadorId);
      setIsEditingMode(true);
      setName(singleEmbassador.name);

      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              singleEmbassador.description
                ? singleEmbassador.description
                : `<p>No Description</p>`
            )
          )
        )
      );

      setFacebookLink(singleEmbassador.FacebookLink);
      setYoutubeLink(singleEmbassador.YoutubeLink);
      setTwitterLink(singleEmbassador.TwitterLink);
      setInstagramLink(singleEmbassador.InstagramLink);
      setIsApproved(singleEmbassador.Approved);
      setImageURL(singleEmbassador.Photo);
      setPreview(singleEmbassador.Photo)
    } else {
      setIsEditingMode(false);
    }
  }, [
    embassadorId,
    singleEmbassador.name,
    singleEmbassador.description,
    singleEmbassador.FacebookLink,
    singleEmbassador.YoutubeLink,
    singleEmbassador.TwitterLink,
    singleEmbassador.InstagramLink,
    singleEmbassador.Approved,
    singleEmbassador.Photo,
  ]);

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage.size > 512000) {
        setShow(true);
        setSelectedImage("");
      }
    }
    if (ambassadorImage) {
      setImageURL(ambassadorImage);
    }
  }, [selectedImage, ambassadorImage]);

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <div className="header">
        <h4 className="title">
          {isEditingMode
            ? `Edit Ambassador: ${singleEmbassador.name}`
            : "Add New Embassador"}
        </h4>
      </div>
      <form
        className="card"
        style={{ minHeight: "auto", padding: "12px 24px" }}
        onSubmit={submitAmbassadorDataHandler}
      >
        <FormGroup>
          <div className="fieldDiv form-group ">
            <label id="embassadorName" className="control-label">
              Embassador Name
            </label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="embassadorName"
              htmlFor="embassadorName"
              placeholder="Enter Name"
              required
            />
          </div>

          <div className="fieldDiv form-group">
            <label id="ambassadorDescription" className="control-label">
              Description
            </label>
            {/* <FormControl
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              name="ambassadorDescription"
              htmlFor="ambassadorDescription"
              placeholder="Enter Description"
              required
            /> */}
            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setDescription(e)}
              onFocus={() => setIsDescriptionRequired(false)}
              onBlur={() =>
                description.getCurrentContent().getPlainText().trim().length <=
                  0 && setIsDescriptionRequired(true)
              }
            />

            {isDescriptionRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Description field is required
              </p>
            )}
          </div>
          <div className="formGroup ">
            <div className="fieldDiv col-md-3 form-group">
              <label id="facebookLink" className="control-label">
                Facebook Link
              </label>
              <FormControl
                type="text"
                value={facebookLink}
                onChange={(e) => setFacebookLink(e.target.value)}
                name="facebookLink"
                htmlFor="facebookLink"
                placeholder="https://www.facebook.com"
              />
            </div>

            <div className="fieldDiv col-md-3 form-group">
              <label id="youtubeLink" className="control-label">
                Youtube Link
              </label>
              <FormControl
                type="text"
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
                name="intID"
                placeholder="https://www.youtube.com"
              />
            </div>

            <div className="fieldDiv col-md-3 form-group">
              <label id="twitterLink" className="control-label">
                Twitter Link
              </label>
              <FormControl
                type="text"
                value={twitterLink}
                onChange={(e) => setTwitterLink(e.target.value)}
                name="twitterLink"
                htmlFor="twitterLink"
                placeholder="https://www.twitter.com"
              />
            </div>

            <div className="fieldDiv col-md-3 form-group">
              <label id="instagarmLink" className="control-label">
                Instagram Link
              </label>
              <FormControl
                type="text"
                value={instagramLink}
                onChange={(e) => setInstagramLink(e.target.value)}
                name="instagarmLink"
                htmlFor="instagarmLink"
                placeholder="https://www.instagram.com"
              />
            </div>
          </div>

          <div
            className="fieldDiv"
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ToggleButton
              className="mb-2 checkboxButton"
              id="toggle-check"
              type="checkbox"
              checked={isApproved}
              onChange={() => setIsApproved((preValue) => !preValue)}
            ></ToggleButton>
            <span
              className=""
              style={{ marginLeft: "5px", marginBottom: "15px" }}
            >
              {isApproved ? "Approved" : "Not Approved"}
            </span>
          </div>
        </FormGroup>
        <div className="fieldDiv">
          <label>
            {isEditingMode ? "Change" : "Upload"} Embassador Image
            <img
              src={preview || defaultImg}
              alt="default"
              style={{ width: "124px", height: "124px", display: "block" }}
            />{" "}
          </label>
          <div className="file-input">
            <input
              style={{ width: "48px", cursor: "pointer" }}
              type="file"
              accept="image/*"
              placeholder="Choose Embassador Image"
              onChange={storeImageHandler}
              className="file"
              id="file"
              ref={fileInputRef}
            />
            <button
              htmlFor="file"
              className="uploadBtn"
              onClick={(event) => {
                event.preventDefault();
                fileInputRef.current.click();
              }}
            >
              {selectedImage || isEditingMode ? "Change " : "Select "}
            </button>
            <button
              className="removeBtn"
              type="button"
              onClick={() => {
                setSelectedImage(null);
                setPreview(null);
              }}
            >
              Remove
            </button>
          </div>
          {selectedImage && (
            <React.Fragment>
              {!imageURL&&<p>Please Upload the image</p>}
              
              <button
              style={{marginTop:".4em"}}
                onClick={uploadImageHandler}
                className="uploadBtn"
                >
                Upload Image
              </button>
            </React.Fragment>
          )}
        </div>

        <div className="submit">
          <Button className="create-edit-form-btn" type="submit">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
        </div>
      </form>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
          setShow={setShow}
          show={show}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    singleEmbassador: state.BrandEmbassadors.singleEmbassador,
    isPending: state.BrandEmbassadors.isPending,
    isSingleEmbassadorPending: state.BrandEmbassadors.isSingleEmbassadorPending,
    ambassadorImage: state.BrandEmbassadors.ambassadorImage,
  }),
  (dispatch) => ({
    getSingleBrandEmbassador: (id) => dispatch(getSingleBrandEmbassador(id)),
    editEmbassador: (id, data) => dispatch(editEmbassador(id, data)),
    createNewEmbassador: (data) => dispatch(createNewEmbassador(data)),
    uploadAmbassadorPhoto: (image) => dispatch(uploadAmbassadorPhoto(image)),
  })
)(AddBrandEmbassador);
