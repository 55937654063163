// import { Pagination } from "antd";
import React, { useEffect } from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import BrandEmbassadorsDataTable from "../../components/BrandEmbassadorsDataTable/BrandEmbassadorsDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NewDataTable from "../../components/NewDataTable";
import {
  approveEmbassador,
  deleteEmbassador,
  getAllBrandEmbassadors,
} from "../../reducers/BrandEmbassadors";
import { Pagination, PaginationItem } from "@material-ui/lab";
import toaster from "../../modules/toaster";

const rowsPerPage = 10;

const BrandEmbassadors = ({
  isPending,
  allBrandEmbassadors,
  getAllBrandEmbassadors,
  approveEmbassador,
  deleteEmbassador,
  history,
}) => {
  const addNewEmbassadorHandler = () => {
    history.push("addNewEmbassador");
  };
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    Image: true,
    Name: true,
    Description: false,
    Createdat: true,
    SocialMedia: false,
    objectId: false,
    Approved: true,
    Actions: true,
  });
  const selecttableHead = [
    "Image",
    "Name",
    "Description",
    "Createdat",
    "SocialMedia",
    "objectId",
    "Approved",
    "Actions",
  ];
  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };
  useEffect(() => {
    getAllBrandEmbassadors();
  }, []);
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleDelete=()=>{
    if (selected.length === 0) {
      toaster.warning("please select some Embassadors");
    } else {
      Promise.allSettled(
        selected.map(e=> {
          return deleteEmbassador(e)
        })
      ).then(responseArr => {
        responseArr.forEach(res=>{
          console.log(res);
      
        })
      })
      setSelected([])

    }

  
  }
  const handlePaginationChange = (event, value) => {
    setPage(value);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  if (isPending) {
    return <LoadingSpinner />;
  }
  const sortedBrandEmbassadors = allBrandEmbassadors.sort(
    (a, b) => Number(b.Approved) - Number(a.Approved)
  );
  return (
    <div>
      <div className="header">
        <h4 className="title">Brand Ambassadors</h4>
      </div>
      <div className="card">
        {sortedBrandEmbassadors.length > 0 ? (
          <NewDataTable
            csvTitle={"brand embassadors"}
            title={"Add New Ambassadors"}
            add={addNewEmbassadorHandler}
            data={sortedBrandEmbassadors}
            tableHead={TableHead}
            selected={selected}
            setSelected={setSelected}
            selecttableHead={selecttableHead}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
          
              <Pagination 
              // size="small" 
              page={page}
              count={Math.ceil(sortedBrandEmbassadors.length/10)}
              // total={projects.length} 
              onChange={handlePaginationChange} />
            }
          >
            {sortedBrandEmbassadors
              ?.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.objectId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <BrandEmbassadorsDataTable
                    key={row.objectId}
                    embassador={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    approveEmbassador={approveEmbassador}
                    deleteEmbassador={deleteEmbassador}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
          <p style={{ textAlign: "center" }}>No Ambassadors Found</p>
          <button
            className="addButton"
            onClick={() => {
              addNewEmbassadorHandler
            }}
          >
            <BsPlus size={24} />
            Add A new Ambassador
          </button>
        </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    isPending: state.BrandEmbassadors.isPending,
    allBrandEmbassadors: state.BrandEmbassadors.allBrandEmbassadors,
  }),
  (dispatch) => ({
    getAllBrandEmbassadors: () => dispatch(getAllBrandEmbassadors()),
    deleteEmbassador: (id) => dispatch(deleteEmbassador(id)),
    approveEmbassador: (id, body) => dispatch(approveEmbassador(id, body)),
  })
)(BrandEmbassadors);
