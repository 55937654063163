import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import "assets/styles/base.scss";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { fireAutomator } from "../../api";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Switch from "../Switch";
import "./InMemoryDataTable.css";

const InMemoryDataTable = ({
  person,
  approveInMemoryPerson,
  deleteInMemoryPerson,
  apiKey,
  projectId,
  isItemSelected,
  handleClick,
  labelId,
  headSelected,
  getInMemoryPersons
}) => {
  const [isPersonApproved, setIsPersonApproved] = useState();
  const [show, setShow] = useState(false);
  const toggleActive = () => {
    approveInMemoryPerson(person.objectId, {
      Approved: isPersonApproved ? false : true,
    });
    let triggerPoint = "";
    if (isPersonApproved && person.language == "en")
      triggerPoint = "inMemoryOfDisApproveEN";
    if (!isPersonApproved && person.language == "en")
      triggerPoint = "inMemoryOfApproveEN";
    if (
      isPersonApproved &&
      (person.language == "ar" || _.isUndefined(person.language))
    )
      triggerPoint = "inMemoryOfDisApproveAR";
    if (
      !isPersonApproved &&
      (person.language == "ar" || _.isUndefined(person.language))
    )
      triggerPoint = "inMemoryOfApproveAR";
    fireAutomator(
      projectId,
      triggerPoint,
      person.userId,
      person.applicantName,
      apiKey
    );
  };

  const openConfirmModalHandler = () => {
    setShow(true);
  };

  const deleteMemoryPersonHandler = () => {
    deleteInMemoryPerson(person.objectId);
  };

  useEffect(() => {
    setIsPersonApproved(person.Approved);
  }, [isPersonApproved]);

  return (
    <React.Fragment>
      <TableRow
        hover
      
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={person.objectId}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, person.objectId)}
            role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>

        {headSelected.UserId && <TableCell>{person.objectId}</TableCell>}
        {headSelected.Image && (
          <TableCell>
            <img
              className="inMemory-person-img"
              src={person.image}
              alt={`${person.name}`}
            />
          </TableCell>
        )}
        {headSelected.Name && <TableCell>{person.name}</TableCell>}
        {headSelected.Age && <TableCell>{person.age}</TableCell>}
        {headSelected.applicantName && (
          <TableCell>{person.applicantName}</TableCell>
        )}
        {headSelected.collectedAmount && (
          <TableCell>{person.collectedAmount}</TableCell>
        )}
        {headSelected.targetAmount && (
          <TableCell>{person.targetAmount}</TableCell>
        )}
        {headSelected.Mobile && <TableCell>{person.mobileNum}</TableCell>}
        {headSelected.Deeplink && (
          <TableCell>
            jonation://inmemory/{person.objectId}
            <i
              className="fa fa-clone pointer"
              onClick={() => {
                navigator.clipboard.writeText(
                  `jonation://inmemory/${person.objectId}`
                );
              }}
            ></i>
          </TableCell>
        )}
        {headSelected.Description && (
          <TableCell
            dangerouslySetInnerHTML={{ __html: person.description }}
          ></TableCell>
        )}
        {headSelected.CreatedAt && (
          <TableCell>{dateFormatter(person.createdAt)}</TableCell>
        )}
        {headSelected.OrgId && <TableCell>{person.orgId}</TableCell>}
        {headSelected.ActId && <TableCell>{person.actId}</TableCell>}
        {headSelected.sdl && <TableCell>{person.sdl}</TableCell>}
        {headSelected.Approved && (
          <TableCell>
            <Switch
              value={isPersonApproved}
              onText={"Yes"}
              offText={"No"}
              onChange={toggleActive}
            />
          </TableCell>
        )}
        {headSelected.Actions && (
          <TableCell >
            <div className="TableIcons">

            <Link to={`/editInMemory/${person.objectId}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>
            <RiDeleteBinLine
              cursor="pointer"
              onClick={openConfirmModalHandler}
              color="#ED1650"
              size={24}
            />
            </div>
          </TableCell>
        )}
      </TableRow>
      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${person.name} from in memory?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteMemoryPersonHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default InMemoryDataTable;
