import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import "assets/styles/base.scss";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import KeywordDataTable from "../../components/KeywordDataTable";
import NewDataTable from "../../components/NewDataTable";
import {
  createKeyword,
  deleteKeyword,
  editKeyword,
  filterKeywords,
  getKeyword,
  getKeywords,
  getKeywordsCount,
} from "../../reducers/Keywords";
import toaster from "../../modules/toaster";

const selecttableHead = [
  "Title",
  "CreatedAt",
  "UpdatedAt",
  "organizationName",
  "Actions",
];

const rowsPerPage = 10;

const Keywords = ({
  orgWithId,
  keywordsCount,
  getKeywords,
  getKeywordsCount,
  createKeyword,
  getKeyword,
  initKeyword,
  deleteKeyword,
  editKeyword,
  filterKeywords,
  filteredKeywords,
}) => {
  const [organizationID, setOrganizationID] = useState([]);
  const [formOperation, setFormOperation] = useState("");
  const [form, setForm] = useState(null);
  const [title, setTitle] = useState("");
  const [org_id, setOrgId] = useState("");
  const [orgIdFilter, setOrgIdFilter] = useState("all");
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = React.useState([]);

  const [headSelected, setHeadSelected] = React.useState({
    Title: true,
    CreatedAt: true,
    UpdatedAt: true,
    organizationName: true,
    Actions: true,
  });
  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const useStyles = makeStyles(() => ({
    table: {
      minWidth: 650,
    },
    formControl: {
      minWidth: 120,
    },
    pagination: {
      margin: "1rem",
    },
    columns: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      width: "45%",
    },
    organizationCol: {
      display: "flex",
      flexDirection: "column",
    },
    container: {
      marginBottom: "1rem",
    },
    lastHead: {
      width: "10%",
    },
    mr: {
      marginRight: "3rem",
    },
    ml: {
      marginLeft: "1rem",
    },
    flexEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    btn: {
      fontWeight: "bold",
    },
    addBtn: {
      backgroundColor: "#00AEEF",
      color: "#FFF",
    },
    cencelBtn: {
      backgroundColor: "#ED1650",
      color: "#FFF",
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    getKeywords(skip);
    getKeywordsCount();
  }, [skip]);
  useEffect(() => {
    setTitle(initKeyword.title);
    setOrgId(initKeyword.org_id);
    //Oranization With TAKAMUL ORG IDS
    setOrganizationID(orgWithId);
  }, [initKeyword, form, organizationID, orgWithId]);
  const handlePaginationChange = (event, value) => {
    setPage(value);
    setSkip((value - 1) * 20);
  };
  const onClickEdit = (id) => {
    setFormOperation("edit");
    setForm(true);
    getKeyword(id);
    setTitle(initKeyword.title);
    setOrgId(initKeyword.org_id);
  };
  const onClickNew = () => {
    setFormOperation("new");
    setForm(true);
  };
  const onClickDelete = (id) => {
    deleteKeyword(id);
  };
  const handleSelectChange = (e) => {
    setOrgId(e.target.value);
  };
  const filterByOrgName = (e) => {
    filterKeywords(e.target.value);
    if (e.target.value == "all") {
      getKeywordsCount();
    }
    setOrgIdFilter(e.target.value);
  };
  const getOrgName = (list, orgId) => {
    let orgName = "";
    list?.map((org) => {
      if (org.takamul_ngo_id === orgId) {
        if (org.nameAR) {
          orgName = org.nameAR;
        }
      }
    });
    return orgName;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      if (formOperation === "new") {
        createKeyword({ title: title, org_id: org_id });
      } else if (formOperation === "edit") {
        editKeyword({ title, org_id, objectId: initKeyword.objectId });
      }
      setForm(null);
      getKeywords();
    } catch (e) {
      console.error(e);
    }
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleDelete=()=>{

    if (selected.length === 0) {
      toaster.warning("please select some keywords");
    } else {
      Promise.allSettled(
        selected.map(e=> {
          return deleteKeyword(e)
        })
      ).then(responseArr => {
        responseArr.forEach(res=>{
          console.log(res);
      
        })
      })
      setSelected([])
    }
  
  }
  return (
    <div
      className="card container-fluid"
      style={{ marginTop: "20px", paddingTop: "15px" }}
    >
      {form && (
        <Container className={classes.container} maxWidth="lg">
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={20}
            >
              <Grid className={classes.container} item xs={12}>
                <TextField
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  className={classes.mr}
                  name="title"
                  label="Title"
                  variant="outlined"
                  style={{ width: "75%" }}
                />
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-controlled-open-select-label">
                    Organization
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    value={org_id}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {_.map(organizationID, (org) => (
                      <MenuItem value={org.takamul_ngo_id}>
                        {org.nameAR}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid>
                {formOperation === "new" ? (
                  <Button
                    type="submit"
                    variant="contained"
                    className={[classes.mr, classes.btn, classes.addBtn]}
                  >
                    Add
                  </Button>
                ) : formOperation === "edit" ? (
                  <Button
                    type="submit"
                    variant="contained"
                    className={[classes.mr, classes.btn, classes.addBtn]}
                  >
                    Edit
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  variant="contained"
                  onClick={() => setForm(null)}
                  className={[classes.btn, classes.cencelBtn]}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      )}
      {!form && (
        <Container
          className={classes.container}
          style={{ display: "flex" }}
          maxWidth="lg"
        >
          <div className={classes.columns}>
            Organization
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={openFilter}
              onClose={() => setOpenFilter(false)}
              onOpen={() => setOpenFilter(true)}
              value={orgIdFilter}
              className={classes.ml}
              onChange={filterByOrgName}
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              {_.map(organizationID, (org) => (
                <MenuItem value={org.takamul_ngo_id}>{org.nameAR}</MenuItem>
              ))}
            </Select>
          </div>
        </Container>
      )}
      <NewDataTable
        title={"Add New Keyword"}
        csvTitle={"keywords"}
        add={onClickNew}
        data={filteredKeywords}
        selected={selected}
        setSelected={setSelected}
        tableHead={TableHead}
        selecttableHead={selecttableHead}
        handleChange={handleChange}
        headSelected={headSelected}
        handleDelete={handleDelete}
        pagination={
          <Pagination 
          // size="small" 
          page={page}
          count={Math.ceil(filteredKeywords.length/10)}
          // total={projects.length} 
          onChange={handlePaginationChange} />
        }
      >
        {filteredKeywords
          .slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const isItemSelected = isSelected(row.objectId);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <KeywordDataTable
                key={row.objectId}
                data={filteredKeywords}
                keyword={row}
                handleClick={handleClick}
                isItemSelected={isItemSelected}
                labelId={labelId}
                getOrgName={getOrgName}
                headSelected={headSelected}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
                orgWithId={orgWithId}
              />
            );
          })}
      </NewDataTable>
    </div>
  );
};

export default connect(
  (state) => ({
    orgWithId: state.ORGs.orgWithId,
    orgs: state.ORGs.orgs,
    keywords: state.Keywords.keywords,
    keywordsCount: state.Keywords.keywordsCount,
    filteredKeywords: state.Keywords.filteredKeywords,
    initKeyword: state.Keywords.keyword,
  }),
  (dispatch) => ({
    getKeywords: (skip) => dispatch(getKeywords(skip)),
    getKeywordsCount: () => dispatch(getKeywordsCount()),
    createKeyword: (keyword) => dispatch(createKeyword(keyword)),
    editKeyword: (keyword) => dispatch(editKeyword(keyword)),
    deleteKeyword: (objectId) => dispatch(deleteKeyword(objectId)),
    getKeyword: (objectID) => dispatch(getKeyword(objectID)),
    filterKeywords: (org_id) => dispatch(filterKeywords(org_id)),
  })
)(Keywords);
