import { Pagination, PaginationItem } from "@material-ui/lab";
import "assets/styles/base.scss";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import NewDataTable from "../../../components/NewDataTable";
import SelectOrg from "../../../components/SelectOrg";
import { deleteProject, loadProjectsList } from "../../../reducers/Projects";
import TableWithSwitch from "./TableWithSwitch";
import "./table.css";
import { BsPlus } from "react-icons/bs";
import toaster from "../../../modules/toaster";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
// import { Pagination } from "antd";


const selecttableHead = [
  "ID",
  "Category",
  "ORG",
  "NameAR",
  "NameEN",
  "DescriptionAR",
  "DescriptionEN",
  "Order",
  "SmartDeeplink",
  "Deeplink",
  "MediaURL",
  "Status",
  "Actions",
];

const rowsPerPage = 10;

const List = ({
  projects,
  selectedOrg,
  loadProjects,
  history,
  deleteProjects,
  isPending,
}) => {
  useEffect(() => {
    loadProjects(selectedOrg);
  }, [selectedOrg,lastDeleted]);
  const [show, setShow] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  // const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [headSelected, setHeadSelected] = React.useState({
    ID: true,
    Category: true,
    ORG: true,
    NameAR: true,
    NameEN: false,
    DescriptionAR: false,
    DescriptionEN: false,
    Order: false,
    SmartDeeplink: true,
    Deeplink: false,
    MediaURL: false,
    Status: true,
    Actions: true,
  });
  const [projectsID, setProjectsID] = useState({
    projectsIDs: selected,
  });
  const [lastDeleted, setLastDeleted] = useState();

  function getKeyByValue(object, value) {
    return Object.keys(object).filter((key) => object[key] === value);
  }

  const TableHead = getKeyByValue(headSelected, true);

  const handleChange = (event) => {
    setHeadSelected({
      ...headSelected,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAddProject = () => {
    if (!selectedOrg || selectedOrg == "") {
      setShow(true);
    } else {
      history.push("/projects/new");
    }
  };

  const handlePaginationChange = (event, value) => {
    // setPage(Number(value + 1));
    setPage(value);
    // {..pageNumber + 1}
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setProjectsID({ projectsIDs: newSelected });
  };
  const isSelected = (name) => {
    selected.indexOf(name) !== -1;
  };
  const handleDelete = () => {
    if(projectsID.projectsIDs.length===0){
      toaster.warning('please select some projects')
    }else{
      deleteProjects(projectsID)
      setLastDeleted(projectsID)
      setSelected([])
      loadProjects(selectedOrg);

    }

  };
  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <React.Fragment>
      <div className="header">
        <h4 className="title">Projects</h4>
      </div>
      <div className="card">
        {projects?.length > 0 ? (
          <NewDataTable
            title={"Add New Project"}
            csvTitle={"Projects"}
            add={handleAddProject}
            data={projects}
            tableHead={TableHead}
            selecttableHead={selecttableHead}
            selected={selected}
            setSelected={setSelected}
            handleChange={handleChange}
            headSelected={headSelected}
            handleDelete={handleDelete}
            pagination={
             
              <Pagination 
              // size="small" 
              page={page}
              count={Math.ceil(projects.length/10)}
              // total={projects.length} 
              onChange={handlePaginationChange} />
            }
          >
            {projects
              ?.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableWithSwitch
                    key={row.id}
                    project={row}
                    handleClick={handleClick}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    headSelected={headSelected}
                  />
                );
              })}
          </NewDataTable>
        ) : (
          <div className="noData">
            <p style={{ textAlign: "center" }}>No Projects Found</p>
            <button
              className="addButton"
              onClick={() => {
                handleAddProject();
              }}
            >
              <BsPlus size={24} />
              Add A New Project
            </button>
          </div>
        )}
      </div>
      {show && (
        <ConfirmModal
          title={"Select organization"}
          handleClose={() => {
            setShow(false);
          }}
          handleShow={() => {
            setShow(true);
          }}
          message={
            <div className="selectOrg">
              Select organization
              <SelectOrg />
            </div>
          }
          show={show}
        >
          <button
            type="button"
            className="confirmBtn"
            disabled={!selectedOrg}
            onClick={() => {
              setShow(false);
            }}
          >
            confirm
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProp = (dispatch) => ({
  loadProjects: (orgId) => dispatch(loadProjectsList(orgId)),
  deleteProjects: (projectsId) => dispatch(deleteProject(projectsId)),
});

export default connect(
  (state) => ({
    selectedOrg: state.ORGs.selectedOrg,
    projects: state.Projects.projects,
    activities: state.Activities.activities,
    orgs: state.ORGs.orgs,
    isPending: state.Projects.isPending,

  }),
  mapDispatchToProp
)(List);

List.propTypes = {
  projects: PropTypes.array,
  selectedOrg: PropTypes.string,
  loadProjects: PropTypes.func,
  history: PropTypes.object,
};
