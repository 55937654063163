import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import "assets/styles/base.scss";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteNgo, getNgos } from "../../reducers/NGOs";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import "./NGOsDataTable.css";
const NGONewsDataTable = (props) => {
  const [show, setShow] = useState(false);

  const {
    ngo,
    editStatus,
    loadAllNGOs,
    deleteNgo,
    handleClick,
    isItemSelected,
    labelId,
    headSelected,
  } = props;

  const openModalHandler = () => setShow(true);

  const handleEditNGOStatus = () => {
    editStatus({
      id: ngo.id,
      new_status: ngo.isactive === 1 ? 0 : 1,
    }).then(() => {
      ngo.isactive = ngo.isactive === 1 ? 0 : 1;
      loadAllNGOs();
    });
  };

  const handleDeleteNgo = () => {
    deleteNgo(ngo.objectId).then(() => {
      loadAllNGOs();
    });
  };

  return (
    <React.Fragment>
      <TableRow
        hover
      
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={ngo.objectId}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, ngo.objectId)}
            role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
        {headSelected.orgId && <TableCell>{ngo.orgId}</TableCell>}
        {headSelected.link && <TableCell>{ngo.link}</TableCell>}
        {headSelected.createdAt && <TableCell>{ngo.createdAt}</TableCell>}

        {headSelected.actions && (
          <TableCell className="TableIcons">
            <Link to={`/editNGONews/${ngo.objectId}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>
            <RiDeleteBinLine
              cursor="pointer"
              onClick={openModalHandler}
              color="#ED1650"
              size={24}
            />
          </TableCell>
        )}
      </TableRow>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete NGO of ${ngo.orgId}?`}
          setShow={setShow}
          show={show}
        >
          <button className="btn btn-danger" onClick={handleDeleteNgo}>
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProp = (dispatch) => ({
  loadAllNGOs: () => dispatch(getNgos()),
  deleteNgo: (ngo_id) => dispatch(deleteNgo(ngo_id)),
});

export default connect(() => ({}), mapDispatchToProp)(NGONewsDataTable);
