import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import "./TeamsDataTable.css";

const TeamsDataTable = ({
  team,
  deleteTeam,
  key,
  handleClick,
  isItemSelected,
  labelId,
  headSelected,
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };

  const deleteTeamHandler = () => {
    deleteTeam(team.objectId);
  };

  return (
    <React.Fragment>
      <TableRow
        hover
    
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={key}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
              onClick={(event) => handleClick(event, team.objectId)}
              role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
        {headSelected.Photo && (
          <TableCell>
            <img className="team-img" src={team.Logo} alt={`${team.Name}`} />
          </TableCell>
        )}
        {headSelected.Name && <TableCell>{team.Name}</TableCell>}
        {headSelected.Title && <TableCell>{team.Title}</TableCell>}
        {headSelected.Actions && (
          <TableCell >
            <div className="TableIcons">

            <Link to={`/editTeam/${team.objectId}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>
            <RiDeleteBinLine
              cursor="pointer"
              onClick={openConfirmModalHandler}
              color="#ED1650"
              size={24}
            />
            </div>
          </TableCell>
        )}
      </TableRow>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${team.Name}?`}
          setShow={setShow}
          show={show}
        >
          <button className="btn btn-danger" onClick={deleteTeamHandler}>
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default TeamsDataTable;
