import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import "assets/styles/base.scss";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Switch from "../Switch";
import "./BrandEmbassadorsDataTable.css";
const BrandEmbassadorsDataTable = ({
  embassador,
  approveEmbassador,
  deleteEmbassador,
  key,
  handleClick,
  isItemSelected,
  labelId,
  headSelected,
}) => {
  const [isApproved, setIsApproved] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setIsApproved(embassador.Approved);
  }, [embassador.Approved]);

  const openModalHandler = () => setShow(true);

  const approveEmbassadorHandler = () =>
    approveEmbassador(embassador.objectId, {
      Approved: isApproved ? false : true,
    });
  const deleteEmbassadorHandler = () => deleteEmbassador(embassador.objectId);

  return (
    <React.Fragment>
      <TableRow
        hover
      
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={key}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, embassador.objectId)}
            role="checkbox"
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
        {headSelected.Image && (
          <TableCell>
            {" "}
            <img
              className="inMemory-person-img"
              src={
                embassador.Photo ||
                `https://cdn.appgain.io/5ce15857bbab6b001018eed0/ikharstga/bUYdY.jpg`
              }
              alt={embassador.name}
            />
          </TableCell>
        )}
        {headSelected.Name && <TableCell>{embassador.name}</TableCell>}
        {headSelected.Description && (
          <TableCell
            dangerouslySetInnerHTML={{ __html: embassador.description }}
            className="description"
          ></TableCell>
        )}
        {headSelected.Createdat && (
          <TableCell>{dateFormatter(embassador.createdAt)}</TableCell>
        )}
        {headSelected.SocialMedia && (
          <TableCell>
            {embassador.FacebookLink && (
              <a href={embassador.FacebookLink} target="_blanck">
                <i className="fa fa-facebook-square social-media-icon"></i>
              </a>
            )}
            {embassador.TwitterLink && (
              <a href={embassador.TwitterLink} target="_blanck">
                <i className="fa fa-twitter-square social-media-icon"></i>
              </a>
            )}
            {embassador.YoutubeLink && (
              <a href={embassador.YoutubeLink} target="_blanck">
                <i className="fa fa-youtube-square social-media-icon"></i>
              </a>
            )}
            {embassador.InstagramLink && (
              <a href={embassador.InstagramLink} target="_blanck">
                <i className="fa fa-instagram social-media-icon"></i>
              </a>
            )}
          </TableCell>
        )}

        {headSelected.objectId && <TableCell>{embassador.objectId}</TableCell>}
        {headSelected.Approved && (
          <TableCell>
            <Switch
              value={isApproved}
              onChange={approveEmbassadorHandler}
              offText={"No"}
              onText={"Yes"}
            />
          </TableCell>
        )}

        {headSelected.Actions && (
          <TableCell>
            <div className="TableIcons">

            <Link to={`/editEmbassador/${embassador.objectId}`}>
              <FaRegEdit size={24} color="#00AEEF" />
            </Link>

            <RiDeleteBinLine
              cursor="pointer"
              onClick={openModalHandler}
              color="#ED1650"
              size={24}
            />
            </div>
          </TableCell>
        )}
      </TableRow>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${embassador.name} from brand embassadors?`}
          setShow={setShow}
          show={show}
        >
          <button className="btn btn-danger" onClick={deleteEmbassadorHandler}>
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default BrandEmbassadorsDataTable;
